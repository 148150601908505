<div class="m-image-upload">
    <!-- {{ 'logo_uploader.label' | translate }} -->

    <div class="m-upload-area" (click)="fileInput.click()" *ngIf="!imageUrl">
        {{ 'logo_uploader.click_to_upload' | translate }}
    </div>

    <div class="m-image-preview" *ngIf="imageUrl">
        <img [src]="imageUrl" *ngIf="imageUrl">
    </div>

    <div class="m-actions">

        <div *ngIf="isImageUploading">{{ imageUploadProgress }}%</div>
        <input #fileInput type="file" (change)="uploadImage($event)" accept="image/*" single style="display:none;" />

        <button mat-icon-button color="primary" (click)="fileInput.value=''; removeImage()"
            matTooltip="{{ 'logo_uploader.remove_file' | translate }}" matTooltipPosition="above" *ngIf="imageUrl">
            <mat-icon>delete_forever</mat-icon>
        </button>
    </div>
</div>