import { TranslateService } from '@ngx-translate/core';
import { map, take, switchMap, catchError } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Subscription } from 'rxjs';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-logo-uploader',
  templateUrl: './logo-uploader.component.html',
  styleUrls: ['./logo-uploader.component.scss']
})
export class LogoUploaderComponent implements OnInit {

  @Input()
  imageId: string;

  @Input()
  showPreviewButton: boolean

  @Output()
  uploadedImageUrl = new EventEmitter<string>();


  imageUrl: string
  isImageUploading: boolean;
  imageUploadProgressSubscription: Subscription;
  imageUploadProgress: string;
  imageUploadErrorMessage: string;
  queueFilesSubscription: Subscription;

  constructor(
    private storage: AngularFireStorage,
    private translate: TranslateService
  ) { }

  ngOnInit() {

  }

  ngOnDestroy() {
    if (this.imageUploadProgressSubscription) {
      this.imageUploadProgressSubscription.unsubscribe();
    }
  }


  uploadImage(event) {
    if (event.target.files[0]) {

      // const generatedQueueFileId = this.adminService.createId();
      this.isImageUploading = true;
      this.imageUploadErrorMessage = '';
      const file = event.target.files[0];
      const fileName = event.target.files[0].name;
      const fielExtension = fileName.substring(fileName.lastIndexOf('.') + 1);
      const filePath = `logo/${this.imageId}.${fielExtension}`;
      const fileRef = this.storage.ref(filePath);

      const task = fileRef.put(file, {
        customMetadata: {
          fileId: this.imageId,
          queueFileName: fileName
        }
      })


      // Observe percentage changes
      this.imageUploadProgressSubscription = task.percentageChanges().pipe(
        map(progress => {
          this.imageUploadProgress = progress.toFixed(0); // no decimal places
          if (progress === 100) {
            this.isImageUploading = false;

            // Get download url
            this.storage.ref(filePath).getDownloadURL().pipe(
              take(1),
              map(url => {
                this.imageUrl = url;
                this.uploadedImageUrl.emit(this.imageUrl);
              })
            ).subscribe();
          }
        }),
        // Catch uload errors
        catchError(error => {
          this.isImageUploading = false;

          // Error codes https://firebase.google.com/docs/storage/web/handle-errors 
          switch (error.code) {
            case 'storage/unauthorized':
              this.imageUploadErrorMessage = this.translate.instant('logo_uploader.errors.unauthorized'); 
              break;
            default:
              this.imageUploadErrorMessage = this.translate.instant('logo_uploader.errors.unknow_error');
              break;
          }
          throw error;

        })
      ).subscribe();
    }
  }

  removeImage() {
    this.imageUrl = '';
    this.uploadedImageUrl.emit('');
  }

}
