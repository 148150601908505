import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    public translateService: TranslateService,
  ) {
    
    // Init translation service
    const languages = ['en', 'pl'];
    this.translateService.addLangs(languages);
    this.translateService.setDefaultLang('pl');

    for (const lang of languages) {
      this.translateService.setTranslation(lang, require('../i18n/' + lang + '.json'));
    }

    const browserLang = translateService.getBrowserLang();
    this.translateService.use(browserLang.match(/en|pl/) ? browserLang : 'pl');
  }
}
