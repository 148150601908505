<div class="container-fluid">

  <div class="m-signup-form">
    
    <div class="row">
      <div class="col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
        
        
        
        <div class="m-logo">
          <img src="/assets/img/migam_logo.svg" alt="">
        </div>
        
        <div class="m-help-info">
          {{ 'signup_form.help_info' | translate}} <a href="tel:{{ 'signup_form.help_info_phone' | translate}}">{{ 'signup_form.help_info_phone' | translate}}</a>
        </div>

        <form [formGroup]="signupForm" novalidate>

          <!-- Person information -->
          <div class="m-section-title">
            {{ 'signup_form.person_info_title' | translate }}
          </div>

          <div class="row">
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'signup_form.person_first_name' | translate}}</mat-label>
                <input matInput type="text" formControlName="personFirstName" required>
                <mat-hint
                  *ngIf="signupForm.get('personFirstName').hasError('required') && signupForm.get('personFirstName').touched"
                  align="end">{{ 'signup_form.validations.field_required' | translate }}</mat-hint>
              </mat-form-field>
            </div>

            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'signup_form.person_last_name' | translate}}</mat-label>
                <input matInput type="text" formControlName="personLastName" required>
                <mat-hint
                  *ngIf="signupForm.get('personLastName').hasError('required') && signupForm.get('personLastName').touched"
                  align="end">{{ 'signup_form.validations.field_required' | translate }}</mat-hint>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'signup_form.person_email' | translate}}</mat-label>
                <input matInput type="email" formControlName="personEmail" required>
                <!--Required error-->
                <mat-hint
                  *ngIf="signupForm.get('personEmail').hasError('required') && signupForm.get('personEmail').touched"
                  align="end">{{ 'signup_form.validations.field_required' | translate }}</mat-hint>
                <!--Wrong email error-->
                <mat-hint
                  *ngIf="!signupForm.get('personEmail').hasError('required') && signupForm.get('personEmail').hasError('email') && signupForm.get('personEmail').touched"
                  align="end">{{ 'signup_form.validations.email_invalid' | translate }}</mat-hint>
              </mat-form-field>
            </div>

            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'signup_form.person_phone' | translate}}</mat-label>
                <input matInput type="text" formControlName="personPhone" required>
                <mat-hint
                  *ngIf="signupForm.get('personPhone').hasError('required') && signupForm.get('personPhone').touched"
                  align="end">{{ 'signup_form.validations.field_required' | translate }}</mat-hint>
              </mat-form-field>
            </div>
          </div>



          <!-- Company information -->
          <div class="m-section-title">
            {{ 'signup_form.company_info_title' | translate }}
            <div class="m-section-subtitle">
              {{ 'signup_form.company_info_subtitle' | translate }}
            </div>
          </div>

          <div class="row">
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'signup_form.company_name' | translate}}</mat-label>
                <input matInput type="text" formControlName="companyName" required>
                <mat-hint
                  *ngIf="signupForm.get('companyName').hasError('required') && signupForm.get('companyName').touched"
                  align="end">{{ 'signup_form.validations.field_required' | translate }}</mat-hint>
              </mat-form-field>
            </div>

            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'signup_form.company_nip' | translate}}</mat-label>
                <input matInput type="text" formControlName="companyNip" required>
                <!-- Required error -->
                <mat-hint
                  *ngIf="signupForm.get('companyNip').hasError('required') && signupForm.get('companyNip').touched"
                  align="end">{{ 'signup_form.validations.field_required' | translate }}</mat-hint>
                <!-- Wrong nip error -->
                <mat-hint
                  *ngIf="!signupForm.get('companyNip').hasError('required') && signupForm.get('companyNip').hasError('nip') && signupForm.get('companyNip').touched"
                  align="end">{{ 'signup_form.validations.nip_invalid' | translate }}</mat-hint>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'signup_form.company_street' | translate}}</mat-label>
                <input matInput type="text" formControlName="companyStreet" required>
                <mat-hint
                  *ngIf="signupForm.get('companyStreet').hasError('required') && signupForm.get('companyStreet').touched"
                  align="end">{{ 'signup_form.validations.field_required' | translate }}</mat-hint>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'signup_form.company_zip' | translate}}</mat-label>
                <input matInput type="text" formControlName="companyZip" required>
                <mat-hint
                  *ngIf="signupForm.get('companyZip').hasError('required') && signupForm.get('companyZip').touched"
                  align="end">{{ 'signup_form.validations.field_required' | translate }}</mat-hint>
                <mat-hint
                  *ngIf="signupForm.get('companyZip').hasError('pattern') && !signupForm.get('companyZip').hasError('required') && signupForm.get('companyZip').touched"
                  align="start">
                  {{ 'signup_form.validations.zip_invalid' | translate }}
                </mat-hint>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'signup_form.company_city' | translate}}</mat-label>
                <input matInput type="text" formControlName="companyCity" required>
                <mat-hint
                  *ngIf="signupForm.get('companyCity').hasError('required') && signupForm.get('companyCity').touched"
                  align="end">{{ 'signup_form.validations.field_required' | translate }}</mat-hint>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'signup_form.company_country' | translate}}</mat-label>
                <mat-select formControlName="companyCountryCode" required>
                  <mat-option *ngFor="let country of countries" [value]="country.code">
                    {{country.name}}
                  </mat-option>
                </mat-select>
                <mat-hint
                  *ngIf="signupForm.get('companyCountryCode').hasError('required') && signupForm.get('companyCountryCode').touched"
                  align="end">{{ 'signup_form.validations.field_required' | translate }}</mat-hint>
              </mat-form-field>
            </div>
          </div>

          <!-- Service delivery address -->
          <div class="row">
            <div class="col">

              <div class="m-same-switch">
                <mat-checkbox formControlName="differentDeliveryAddressAsCompanyAddress"
                  (change)="toggleDifferentServiceDeliveryAddressAsCompanyAddress($event)"> {{
                  'signup_form.service_delivery_address_option' | translate }} </mat-checkbox>
              </div>

              <ng-container *ngIf="isDifferentServiceDeliveryAddressAsCompanyAddress">

                <div class="m-section-title">
                  {{ 'signup_form.service_delivery_title' | translate }}
                  <div class="m-section-subtitle">
                    {{ 'signup_form.service_delivery_subtitle' | translate }}
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <mat-form-field appearance="outline">
                      <mat-label>{{ 'signup_form.service_delivery_name' | translate}}</mat-label>
                      <input matInput type="text" formControlName="serviceDeliveryName" required>
                      <mat-hint
                        *ngIf="signupForm.get('serviceDeliveryName').hasError('required') && signupForm.get('serviceDeliveryName').touched"
                        align="end">{{ 'signup_form.validations.field_required' | translate }}</mat-hint>
                    </mat-form-field>
                  </div>

                  <div class="col">
                    <mat-form-field appearance="outline">
                      <mat-label>{{ 'signup_form.service_delivery_nip' | translate}}</mat-label>
                      <input matInput type="text" formControlName="serviceDeliveryNip">
                      <!-- Required error -->
                      <!-- <mat-hint
                          *ngIf="signupForm.get('serviceDeliveryNip').hasError('required') && signupForm.get('serviceDeliveryNip').touched"
                          align="end">{{ 'signup_form.validations.field_required' | translate }}</mat-hint> -->
                      <!-- Wrong nip error -->
                      <mat-hint
                        *ngIf="!signupForm.get('serviceDeliveryNip').hasError('required') && signupForm.get('serviceDeliveryNip').hasError('nip') && signupForm.get('serviceDeliveryNip').touched"
                        align="end">{{ 'signup_form.validations.nip_invalid' | translate }}</mat-hint>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <mat-form-field appearance="outline">
                      <mat-label>{{ 'signup_form.service_delivery_street' | translate}}</mat-label>
                      <input matInput type="text" formControlName="serviceDeliveryStreet" required>
                      <mat-hint
                        *ngIf="signupForm.get('serviceDeliveryStreet').hasError('required') && signupForm.get('serviceDeliveryStreet').touched"
                        align="end">{{ 'signup_form.validations.field_required' | translate }}</mat-hint>
                    </mat-form-field>
                  </div>
                  <div class="col">
                    <mat-form-field appearance="outline">
                      <mat-label>{{ 'signup_form.service_delivery_zip' | translate}}</mat-label>
                      <input matInput type="text" formControlName="serviceDeliveryZip" required>
                      <mat-hint
                        *ngIf="signupForm.get('serviceDeliveryZip').hasError('required') && signupForm.get('serviceDeliveryZip').touched"
                        align="end">{{ 'signup_form.validations.field_required' | translate }}</mat-hint>
                      <mat-hint
                        *ngIf="signupForm.get('serviceDeliveryZip').hasError('pattern') && !signupForm.get('serviceDeliveryZip').hasError('required') && signupForm.get('serviceDeliveryZip').touched"
                        align="start">
                        {{ 'signup_form.validations.zip_invalid' | translate }}
                      </mat-hint>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <mat-form-field appearance="outline">
                      <mat-label>{{ 'signup_form.service_delivery_city' | translate}}</mat-label>
                      <input matInput type="text" formControlName="serviceDeliveryCity" required>
                      <mat-hint
                        *ngIf="signupForm.get('serviceDeliveryCity').hasError('required') && signupForm.get('serviceDeliveryCity').touched"
                        align="end">{{ 'signup_form.validations.field_required' | translate }}</mat-hint>
                    </mat-form-field>
                  </div>
                  <div class="col">
                    <mat-form-field appearance="outline">
                      <mat-label>{{ 'signup_form.service_delivery_country' | translate}}</mat-label>
                      <mat-select formControlName="serviceDeliveryCountryCode" required>
                        <mat-option *ngFor="let country of countries" [value]="country.code">
                          {{country.name}}
                        </mat-option>
                      </mat-select>
                      <mat-hint
                        *ngIf="signupForm.get('serviceDeliveryCountryCode').hasError('required') && signupForm.get('serviceDeliveryCountryCode').touched"
                        align="end">{{ 'signup_form.validations.field_required' | translate }}</mat-hint>
                    </mat-form-field>
                  </div>
                </div>
              </ng-container>

            </div>
          </div>

          <!-- Service delivery phone -->
          <div class="row">
            <div class="col">
              <div class="m-section-title">
                {{ 'signup_form.service_delivery_phone_info_title' | translate }}
                <div class="m-section-subtitle">
                  {{ 'signup_form.service_delivery_phone_info_subtitle' | translate }}
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ 'signup_form.service_delivery_phone' | translate}}</mat-label>
                    <!-- <input matInput type="text" formControlName="serviceDeliveryPhone" required> -->
                    <textarea matInput type="text" formControlName="serviceDeliveryPhone" required cdkTextareaAutosize
                      cdkAutosizeMinRows="1" cdkAutosizeMaxRows="15"></textarea>
                    <mat-hint
                      *ngIf="signupForm.get('serviceDeliveryPhone').hasError('required') && signupForm.get('serviceDeliveryPhone').touched"
                      align="end">{{ 'signup_form.validations.field_required' | translate }}</mat-hint>
                  </mat-form-field>
                </div>
              </div>

            </div>
          </div>
          <!-- Queue opening hours -->
          <div class="row">
            <div class="col">

              <div class="m-section-title">
                {{ 'signup_form.opening_hours_title' | translate }}
                <div class="m-section-subtitle">
                  {{ 'signup_form.opening_hours_subtitle' | translate }}
                </div>
              </div>



              <div class="m-same-switch">
                <mat-checkbox formControlName="differentWorkingHoursDuringWorkdays"
                  (change)="toggleDifferentWorkingHoursDuringWorkdays($event)"> {{
                  'signup_form.opening_hours_working_days_option' | translate }} </mat-checkbox>
              </div>

              <div class="row">
                <div class="col-12 col-sm-5 col-md-4" *ngIf="!isDifferentWorkingHoursDuringWorkdays">
                  <mat-checkbox formControlName="weekdayIsOpened"
                    (change)="toggleOpenHoursDay($event, 'weekdayIsOpened')">
                    {{ 'signup_form.weekday' | translate }}
                  </mat-checkbox>
                  <div class="m-time-inputs">
                    <mat-form-field appearance="outline">
                      <mat-label>{{ 'signup_form.open_time' | translate }}</mat-label>
                      <mat-select formControlName="weekdayOpenTime">
                        <mat-option *ngFor="let openTime of openTimes" [value]="openTime">
                          {{openTime}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                      <mat-label>{{ 'signup_form.close_time' | translate }}</mat-label>
                      <mat-select formControlName="weekdayCloseTime">
                        <mat-option *ngFor="let closeTime of closeTimes" [value]="closeTime">
                          {{closeTime}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <div class="col-12 col-sm-5 col-md-4" *ngIf="isDifferentWorkingHoursDuringWorkdays">
                  <mat-checkbox formControlName="mondayIsOpened"
                    (change)="toggleOpenHoursDay($event, 'mondayIsOpened')">
                    {{ 'signup_form.days_of_week.monday' | translate }}
                  </mat-checkbox>
                  <div class="m-time-inputs">
                    <mat-form-field appearance="outline">
                      <mat-label>{{ 'signup_form.open_time' | translate }}</mat-label>
                      <mat-select formControlName="mondayOpenTime">
                        <mat-option *ngFor="let openTime of openTimes" [value]="openTime">
                          {{openTime}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                      <mat-label>{{ 'signup_form.close_time' | translate }}</mat-label>
                      <mat-select formControlName="mondayCloseTime">
                        <mat-option *ngFor="let closeTime of closeTimes" [value]="closeTime">
                          {{closeTime}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <div class="col-12 col-sm-5 col-md-4" *ngIf="isDifferentWorkingHoursDuringWorkdays">
                  <mat-checkbox formControlName="tuesdayIsOpened"
                    (change)="toggleOpenHoursDay($event, 'tuesdayIsOpened')">
                    {{ 'signup_form.days_of_week.tuesday' | translate }}</mat-checkbox>
                  <div class="m-time-inputs">
                    <mat-form-field appearance="outline">
                      <mat-label>{{ 'signup_form.open_time' | translate }}</mat-label>
                      <mat-select formControlName="tuesdayOpenTime">
                        <mat-option *ngFor="let openTime of openTimes" [value]="openTime">
                          {{openTime}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                      <mat-label>{{ 'signup_form.close_time' | translate }}</mat-label>
                      <mat-select formControlName="tuesdayCloseTime">
                        <mat-option *ngFor="let closeTime of closeTimes" [value]="closeTime">
                          {{closeTime}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <div class="col-12 col-sm-5 col-md-4" *ngIf="isDifferentWorkingHoursDuringWorkdays">
                  <mat-checkbox formControlName="wednesdayIsOpened"
                    (change)="toggleOpenHoursDay($event, 'wednesdayIsOpened')">
                    {{ 'signup_form.days_of_week.wednesday' | translate }}</mat-checkbox>
                  <div class="m-time-inputs">
                    <mat-form-field appearance="outline">
                      <mat-label>{{ 'signup_form.open_time' | translate }}</mat-label>
                      <mat-select formControlName="wednesdayOpenTime">
                        <mat-option *ngFor="let openTime of openTimes" [value]="openTime">
                          {{openTime}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                      <mat-label>{{ 'signup_form.close_time' | translate }}</mat-label>
                      <mat-select formControlName="wednesdayCloseTime">
                        <mat-option *ngFor="let closeTime of closeTimes" [value]="closeTime">
                          {{closeTime}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <div class="col-12 col-sm-5 col-md-4" *ngIf="isDifferentWorkingHoursDuringWorkdays">
                  <mat-checkbox formControlName="thursdayIsOpened"
                    (change)="toggleOpenHoursDay($event, 'thursdayIsOpened')">
                    {{ 'signup_form.days_of_week.thursday' | translate }}</mat-checkbox>
                  <div class="m-time-inputs">
                    <mat-form-field appearance="outline">
                      <mat-label>{{ 'signup_form.open_time' | translate }}</mat-label>
                      <mat-select formControlName="thursdayOpenTime">
                        <mat-option *ngFor="let openTime of openTimes" [value]="openTime">
                          {{openTime}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                      <mat-label>{{ 'signup_form.close_time' | translate }}</mat-label>
                      <mat-select formControlName="thursdayCloseTime">
                        <mat-option *ngFor="let closeTime of closeTimes" [value]="closeTime">
                          {{closeTime}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <div class="col-12 col-sm-5 col-md-4" *ngIf="isDifferentWorkingHoursDuringWorkdays">
                  <mat-checkbox formControlName="fridayIsOpened"
                    (change)="toggleOpenHoursDay($event, 'fridayIsOpened')">
                    {{ 'signup_form.days_of_week.friday' | translate }}</mat-checkbox>
                  <div class="m-time-inputs">
                    <mat-form-field appearance="outline">
                      <mat-label>{{ 'signup_form.open_time' | translate }}</mat-label>
                      <mat-select formControlName="fridayOpenTime">
                        <mat-option *ngFor="let openTime of openTimes" [value]="openTime">
                          {{openTime}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                      <mat-label>{{ 'signup_form.close_time' | translate }}</mat-label>
                      <mat-select formControlName="fridayCloseTime">
                        <mat-option *ngFor="let closeTime of closeTimes" [value]="closeTime">
                          {{closeTime}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <!-- <div class="col">
                    <mat-checkbox formControlName="saturdayIsOpened"
                      (change)="toggleOpenHoursDay($event, 'saturdayIsOpened')">
                      {{ 'signup_form.days_of_week.saturday' | translate }}</mat-checkbox>
                    <div class="m-time-inputs">
                      <mat-form-field appearance="outline">
                        <mat-label>{{ 'signup_form.open_time' | translate }}</mat-label>
                        <mat-select formControlName="saturdayOpenTime">
                          <mat-option *ngFor="let openTime of openTimes" [value]="openTime">
                            {{openTime}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                        <mat-label>{{ 'signup_form.close_time' | translate }}</mat-label>
                        <mat-select formControlName="saturdayCloseTime">
                          <mat-option *ngFor="let closeTime of closeTimes" [value]="closeTime">
                            {{closeTime}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="col">
                    <mat-checkbox formControlName="sundayIsOpened"
                      (change)="toggleOpenHoursDay($event, 'sundayIsOpened')">
                      {{ 'signup_form.days_of_week.sunday' | translate }}</mat-checkbox>
                    <div class="m-time-inputs">
                      <mat-form-field appearance="outline">
                        <mat-label>{{ 'signup_form.open_time' | translate }}</mat-label>
                        <mat-select formControlName="sundayOpenTime">
                          <mat-option *ngFor="let openTime of openTimes" [value]="openTime">
                            {{openTime}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                        <mat-label>{{ 'signup_form.close_time' | translate }}</mat-label>
                        <mat-select formControlName="sundayCloseTime">
                          <mat-option *ngFor="let closeTime of closeTimes" [value]="closeTime">
                            {{closeTime}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div> -->

              </div>

            </div>
          </div>

          <!-- Error info -->
          <div class="m-error-info" *ngIf="submitFormErrorMsg">
            {{ submitFormErrorMsg }}
          </div>

          <div class="m-cost-info">
            {{ 'signup_form.cost_info' | translate }}
          </div>

          <!-- Submit button -->
          <div class="m-submit-button">
            <button type="button" mat-flat-button color="primary" class="login-button"
              [disabled]="!signupForm.valid || isSubmitProcessing" (click)="onSubmitButtonClick()">

              <span *ngIf="!isSubmitProcessing">{{ 'signup_form.submit_button' | translate }}</span>

              <mat-spinner diameter="25" *ngIf="isSubmitProcessing"></mat-spinner>
              <span *ngIf="isSubmitProcessing">{{ 'signup_form.submit_button_processing' | translate }}</span>

            </button>
          </div>

          <!-- Terms and privacy policy info -->
          <div class="m-consent-info">
            <p>
              <!-- {{ 'signup_form.terms.part1' | translate }} <a href="https://firebasestorage.googleapis.com/v0/b/migam-78536.appspot.com/o/Migam_Regulamin.pdf?alt=media&token=cd35ba0d-7632-4537-9101-b3b058ac492e" target="_blank" rel="noopener noreferrer">{{ 'signup_form.terms.part2' | translate }}</a> {{ 'signup_form.terms.part3' | translate }}. -->
              {{ 'signup_form.terms.part1' | translate }} <a href="https://migam.org/pl/content/regulamin-uslugi-plus"
                target="_blank" rel="noopener noreferrer">{{ 'signup_form.terms.part2' | translate }}</a> {{
              'signup_form.terms.part3' | translate }}.
            </p>
            <p>
              <!-- {{ 'signup_form.privacy_policy.part1' | translate }} <a href="https://firebasestorage.googleapis.com/v0/b/migam-78536.appspot.com/o/Migam_Polityka_Prywatnosci.pdf?alt=media&token=1e1a44d0-a739-4ec6-b03a-3464922a333f" target="_blank" rel="noopener noreferrer">{{ 'signup_form.privacy_policy.part2' | translate }}</a>. -->
              {{ 'signup_form.privacy_policy.part1' | translate }} <a href="https://migam.org/pl/polityka-prywatnosci"
                target="_blank" rel="noopener noreferrer">{{ 'signup_form.privacy_policy.part2' | translate }}</a>.
            </p>
          </div>

        </form>

        <!-- reCaptcha -->
        <re-captcha #reCaptcha="reCaptcha" (resolved)="onReCaptchaTokenResolved($event)"
          (error)="onReCaptchaTokenError($event)" errorMode="handled" [siteKey]="reCaptchaSiteKey" size="invisible">
        </re-captcha>


        <!-- <pre>
          {{ signupForm.valid }}
          {{ signupForm.value | json  }}
        </pre> -->

      </div>
    </div>
  </div>
</div>