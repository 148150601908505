<div class="container-fluid">

  <div class="m-thank-you">

    <div class="row">
      <div class="col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">

        <div class="m-success-info">
          <div class="m-logo">
            <img src="/assets/img/migam_logo.svg" alt="">
          </div>
          {{ 'offer_form.success_info' | translate }}
        </div>

      </div>
    </div>

  </div>
</div>