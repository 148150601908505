<div class="container-fluid">

  <div class="m-cancel-renewal">

    <div class="row">
      <div class="col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">

        <div class="m-success-info">
          <div class="m-logo">
            <img src="/assets/img/migam_logo.svg" alt="">
          </div>
          <h2>{{ 'subscription_renewal_cancellation.title' | translate }}</h2>
          <p>{{ 'subscription_renewal_cancellation.info' | translate }}</p>

          <div class="m-submit-button" *ngIf="!successMessage">
            <button type="button" mat-flat-button color="primary" class="login-button" [disabled]="isProcessing"
              (click)="cancelSubscriptionRenewal()">

              <span *ngIf="!isProcessing">{{ 'subscription_renewal_cancellation.cancel_button' | translate }}</span>

              <mat-spinner diameter="25" *ngIf="isProcessing"></mat-spinner>
              <span *ngIf="isProcessing">{{ 'subscription_renewal_cancellation.processing_info' | translate }}</span>

            </button>
          </div>
          <p *ngIf="successMessage">{{ successMessage }}</p> 
          <p class="m-error-message" *ngIf="errorMessage">{{ errorMessage }}</p> 
        </div>

      </div>
    </div>

  </div>
</div>