import { CloudFunctionResponse, SubscriptionRenewalCancellationPaylod } from './../../../../shared/model/saas.interface';
import { TranslateService } from '@ngx-translate/core';
import { map, catchError, take } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Component({
  selector: 'app-subscription-renewal-cancellation',
  templateUrl: './subscription-renewal-cancellation.component.html',
  styleUrls: ['./subscription-renewal-cancellation.component.scss']
})
export class SubscriptionRenewalCancellationComponent implements OnInit {

  isProcessing = false;
  // cancellationResultMsg: string;
  subscriptionId: string;
  errorMessage: string;
  successMessage: string;

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService,
    private firebaseFunctions: AngularFireFunctions,
  ) { }

  ngOnInit() {
    this.subscriptionId = this.route.snapshot.paramMap.get('subscriptionId');
  }

  cancelSubscriptionRenewal() {
    this.isProcessing = true;
    this.errorMessage = '';
    this.successMessage = '';

    const payload: SubscriptionRenewalCancellationPaylod = {
      subscriptionId: this.subscriptionId
    }

    const subscriptionRenewalCancellationFunction = this.firebaseFunctions.httpsCallable('subscriptionRenewalCancellation');
    subscriptionRenewalCancellationFunction(payload).pipe(
      take(1),
      map((response: CloudFunctionResponse) => {

        if (response.success) {
          this.setSuccessMessage(this.translate.instant('subscription_renewal_cancellation.success_info'))
        } else {
          switch (response.error_code) {
            case 'subscription_not_found':
              this.setErrorMessage(this.translate.instant('subscription_renewal_cancellation.errors.subscription_not_found'));
              break;
            case 'subscription_already_cancelled':
              this.setErrorMessage(this.translate.instant('subscription_renewal_cancellation.errors.subscription_already_cancelled'));
              break;
            case 'subscription_already_renewed':
              this.setErrorMessage(this.translate.instant('subscription_renewal_cancellation.errors.subscription_already_renewed'));
              break;
            default:
              this.setErrorMessage(this.translate.instant('subscription_renewal_cancellation.errors.unknown_error'));
              break;
          }
        }
      }),
      catchError(error => {
        this.setErrorMessage(this.translate.instant('subscription_renewal_cancellation.errors.unknown_error'));
        throw error;
      })
    ).subscribe();
  }

  setSuccessMessage(message: string) {
    this.isProcessing = false;
    this.successMessage = message;
  }

  setErrorMessage(message: string) {
    this.isProcessing = false;
    this.errorMessage = message;
  }

}
