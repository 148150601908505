import { CloudFunctionResponse, OfferProduct } from './../../../../shared/model/saas.interface';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { map, catchError, first, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AcceptOfferPayload, GetOfferPayload, Offer } from '../../../../shared/model/saas.interface';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Component({
  selector: 'app-offer-form',
  templateUrl: './offer-form.component.html',
  styleUrls: ['./offer-form.component.scss']
})
export class OfferFormComponent implements OnInit {

  offer: Offer;
  isOfferLoading: boolean = true;
  isOfferLoadingError: boolean = false;
  offerLoadingErrorMsg: string;
  offerSubscriptionProducts: OfferProduct[] = [];
  offerSubscriptionProductsTotalNetPrice: number = 0;
  offerNonSubscriptionProducts: OfferProduct[] = [];
  offerNonSubscriptionProductsTotalNetPrice: number = 0;

  offerForm: FormGroup;

  isSubmitProcessing: boolean = false;
  isSubmitFormError: boolean = false;
  submitFormErrorMsg: string;

  showLogoUploader: boolean;
  logoImageUrl: string;

  constructor(
    private formBuilder: FormBuilder,
    private firebaseFunctions: AngularFireFunctions,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    const offerId = this.route.snapshot.paramMap.get('offerId');
    this.getOffer(offerId);
  }


  getOffer(offerId: string) {

    const payload: GetOfferPayload = {
      offerId: offerId,
    }
    
    const getOfferDataFunction = this.firebaseFunctions.httpsCallable('getOfferData');
    getOfferDataFunction(payload).pipe(
      take(1),
      map((response: CloudFunctionResponse) => {

        if (response.success) {
          this.offer = response.data;
          this.isOfferLoading = false;

          this.showLogoUploader = this.offer.isOfferForSubscriptionProduct ? true : false;

          this.offer.products.map(product => {
            if (product.isSubscription) {
              this.offerSubscriptionProducts.push(product);
              this.offerSubscriptionProductsTotalNetPrice = this.offerSubscriptionProductsTotalNetPrice + (product.quantity * product.price);
            } else {
              this.offerNonSubscriptionProducts.push(product)
              this.offerNonSubscriptionProductsTotalNetPrice = this.offerNonSubscriptionProductsTotalNetPrice + (product.quantity * product.price);
            }
          });
          this.createOfferForm();

        } else {
          switch (response.error_code) {
            case 'offer_not_found':
              this.setOfferLoadingErrorMessage(this.translate.instant('offer_form.errors.offer_not_found'));
              break;
            case 'offer_already_accepted':
              this.setOfferLoadingErrorMessage(this.translate.instant('offer_form.errors.offer_already_accepted'));
              break;
            case 'offer_cancelled':
              this.setOfferLoadingErrorMessage(this.translate.instant('offer_form.errors.offer_cancelled'));
              break;
            case 'offer_not_sent':
              this.setOfferLoadingErrorMessage(this.translate.instant('offer_form.errors.offer_not_sent'));
              break;
            default:
              this.setOfferLoadingErrorMessage(this.translate.instant('offer_form.errors.unknown_error'));
              break;
          }
        }
      }),
      catchError(error => {
        this.setOfferLoadingErrorMessage(this.translate.instant('offer_form.errors.unknown_error'));
        throw error;
      })
    ).subscribe();

  }

  setOfferLoadingErrorMessage(message: string) {
    this.isOfferLoading = false;
    this.isOfferLoadingError = true;
    this.offerLoadingErrorMsg = message;
  }


  createOfferForm() {
    this.offerForm = this.formBuilder.group({

      invoiceEmail: ['', [Validators.required, Validators.email]],
      // logoUrl: ['', Validators.required]

      phoneNumbersForInterpreters: [
        {
          value: '',
          disabled: this.offer.isOfferForSubscriptionProduct ? false : true
        },
        Validators.required
      ],

    });
  }

  onSubmit() {
    this.isSubmitProcessing = true;

    // Create payload
    const acceptOfferPayload: AcceptOfferPayload = {
      offerId: this.offer.id,
      phoneNumbersForInterpreters: this.offer.isOfferForSubscriptionProduct ? this.offerForm.get('phoneNumbersForInterpreters').value : '',
      invoiceEmail: this.offerForm.get('invoiceEmail').value,
      logoUrl: this.logoImageUrl
    }

    const acceptOfferFunction = this.firebaseFunctions.httpsCallable('acceptOffer');
    acceptOfferFunction(acceptOfferPayload).pipe(
      take(1),
      map((response: CloudFunctionResponse) => {

        if (response.success) {
          this.isSubmitProcessing = false;
          this.router.navigate(['/offer-thank-you']);

        } else {
          switch (response.error_code) {
            case 'offer_not_found':
              this.setSubmitFormError(this.translate.instant('offer_form.errors.offer_not_found'));
              break;
            case 'offer_already_accepted':
              this.setSubmitFormError(this.translate.instant('offer_form.errors.offer_already_accepted'));
              break;
            default:
              this.setSubmitFormError(this.translate.instant('offer_form.errors.unknown_error'));
              break;
          }
        }
      }),
      catchError(error => {
        this.setSubmitFormError(this.translate.instant('offer_form.errors.unknown_error'));
        throw error;
      })
    ).subscribe();
  }

  setSubmitFormError(errorDescription: string) {
    this.isSubmitProcessing = false;
    this.isSubmitFormError = true;
    this.submitFormErrorMsg = this.translate.instant('offer_form.error_title') + ' ' + errorDescription + ' ' + this.translate.instant('offer_form.error_contact_info');
  }

  onUploadedLogo(imageUrl: string) {
    this.logoImageUrl = imageUrl;
  }


}
