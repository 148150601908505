<div class="container-fluid">
  <div class="row">
    <div class="col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
      <div class="m-send-offer-to-client">

        <div class="m-logo">
          <img src="/assets/img/migam_logo.svg" alt="">
        </div>

        <div class="m-info">
          <div *ngIf="isProcessing">
            <div class="m-spinner">
              <mat-spinner diameter="50"></mat-spinner>
            </div>
            {{ 'send_offer_to_client.loading_info' | translate }}
          </div>
          <p *ngIf="!isProcessing">{{ sendResultMsg }}</p>
        </div>

      </div>
    </div>

  </div>
</div>