export const countryDictionary = [
    {
        name: 'AFGANISTAN',
        code: 'AF',
    },
    {
        name: 'ALAND ISLAND',
        code: 'AX',
    },
    {
        name: 'ALBANIA',
        code: 'AL',
    },
    {
        name: 'ALGIERIA',
        code: 'DZ',
    },
    {
        name: 'ANDORA',
        code: 'AD',
    },
    {
        name: 'ANGOLA',
        code: 'AO',
    },
    {
        name: 'ANGUILLA',
        code: 'AI',
    },
    {
        name: 'ANTARKTYDA',
        code: 'AQ',
    },
    {
        name: 'ANTIGUA I BARBUDA',
        code: 'AG',
    },
    {
        name: 'ANTYLE HOLENDERSKIE',
        code: 'AN',
    },
    {
        name: 'ARABIA SAUDYJSKA',
        code: 'SA',
    },
    {
        name: 'ARGENTYNA',
        code: 'AR',
    },
    {
        name: 'ARMENIA',
        code: 'AM',
    },
    {
        name: 'ARUBA',
        code: 'AW',
    },
    {
        name: 'AUSTRALIA',
        code: 'AU',
    },
    {
        name: 'AUSTRIA',
        code: 'AT',
    },
    {
        name: 'AZERBEJDŻAN',
        code: 'AZ',
    },
    {
        name: 'BAHAMY',
        code: 'BS',
    },
    {
        name: 'BAHRAJN',
        code: 'BH',
    },
    {
        name: 'BANGLADESZ',
        code: 'BD',
    },
    {
        name: 'BARBADOS',
        code: 'BB',
    },
    {
        name: 'BELGIA',
        code: 'BE',
    },
    {
        name: 'BELIZE',
        code: 'BZ',
    },
    {
        name: 'BENIN',
        code: 'BJ',
    },
    {
        name: 'BERMUDY',
        code: 'BM',
    },
    {
        name: 'BHUTAN',
        code: 'BT',
    },
    {
        name: 'BIAŁORUŚ',
        code: 'BY',
    },
    {
        name: 'BOLIWIA',
        code: 'BO',
    },
    {
        name: 'BOŚNIA I HERCEGOWINA',
        code: 'BA',
    },
    {
        name: 'BOTSWANA',
        code: 'BW',
    },
    {
        name: 'BRAZYLIA',
        code: 'BR',
    },
    {
        name: 'BRUNEI DARUSSALAM',
        code: 'BN',
    },
    {
        name: 'BRYTYJSKIE TERYTORIUM OCEANU INDYJSKIEGO',
        code: 'IO',
    },
    {
        name: 'BUŁGARIA',
        code: 'BG',
    },
    {
        name: 'BURKINA FASO',
        code: 'BF',
    },
    {
        name: 'BURUNDI',
        code: 'BI',
    },
    {
        name: 'CHILE',
        code: 'CL',
    },
    {
        name: 'CHINY',
        code: 'CN',
    },
    {
        name: 'CHORWACJA',
        code: 'HR',
    },
    {
        name: 'CYPR',
        code: 'CY',
    },
    {
        name: 'CZAD',
        code: 'TD',
    },
    {
        name: 'CZARNOGÓRA',
        code: 'ME',
    },
    {
        name: 'DANIA',
        code: 'DK',
    },
    {
        name: 'DOMINIKA',
        code: 'DM',
    },
    {
        name: 'DOMINIKANA',
        code: 'DO',
    },
    {
        name: 'DŻIBUTI',
        code: 'DJ',
    },
    {
        name: 'EGIPT',
        code: 'EG',
    },
    {
        name: 'EKWADOR',
        code: 'EC',
    },
    {
        name: 'ERYTREA',
        code: 'ER',
    },
    {
        name: 'ESTONIA',
        code: 'EE',
    },
    {
        name: 'ETIOPIA',
        code: 'ET',
    },
    {
        name: 'FALKLANDY',
        code: 'FK',
    },
    {
        name: 'FIDŻI REPUBLIKA',
        code: 'FJ',
    },
    {
        name: 'FILIPINY',
        code: 'PH',
    },
    {
        name: 'FINLANDIA',
        code: 'FI',
    },
    {
        name: 'FRANCJA',
        code: 'FR',
    },
    {
        name: 'FRANCUSKIE TERYTORIUM POŁUDNIOWE',
        code: 'TF',
    },
    {
        name: 'GABON',
        code: 'GA',
    },
    {
        name: 'GAMBIA',
        code: 'GM',
    },
    {
        name: 'GHANA',
        code: 'GH',
    },
    {
        name: 'GIBRALTAR',
        code: 'GI',
    },
    {
        name: 'GRECJA',
        code: 'GR',
    },
    {
        name: 'GRENADA',
        code: 'GD',
    },
    {
        name: 'GRENLANDIA',
        code: 'GL',
    },
    {
        name: 'GRUZJA',
        code: 'GE',
    },
    {
        name: 'GUAM',
        code: 'GU',
    },
    {
        name: 'GUERNSEY',
        code: 'GG',
    },
    {
        name: 'GUJANA',
        code: 'GY',
    },
    {
        name: 'GUJANA FRANCUSKA',
        code: 'GF',
    },
    {
        name: 'GWADELUPA',
        code: 'GP',
    },
    {
        name: 'GWATEMALA',
        code: 'GT',
    },
    {
        name: 'GWINEA',
        code: 'GN',
    },
    {
        name: 'GWINEA RÓWNIKOWA',
        code: 'GQ',
    },
    {
        name: 'GWINEA-BISSAU',
        code: 'GW',
    },
    {
        name: 'HAITI',
        code: 'HT',
    },
    {
        name: 'HISZPANIA',
        code: 'ES',
    },
    {
        name: 'HOLANDIA (NIDERLANDY)',
        code: 'NL',
    },
    {
        name: 'HONDURAS',
        code: 'HN',
    },
    {
        name: 'HONGKONG',
        code: 'HK',
    },
    {
        name: 'INDIE',
        code: 'IN',
    },
    {
        name: 'INDONEZJA',
        code: 'ID',
    },
    {
        name: 'IRAK',
        code: 'IQ',
    },
    {
        name: 'IRAN',
        code: 'IR',
    },
    {
        name: 'IRLANDIA',
        code: 'IE',
    },
    {
        name: 'ISLANDIA',
        code: 'IS',
    },
    {
        name: 'IZRAEL',
        code: 'IL',
    },
    {
        name: 'JAMAJKA',
        code: 'JM',
    },
    {
        name: 'JAPONIA',
        code: 'JP',
    },
    {
        name: 'JEMEN',
        code: 'YE',
    },
    {
        name: 'JERSEY',
        code: 'JE',
    },
    {
        name: 'JORDANIA',
        code: 'JO',
    },
    {
        name: 'KAJMANY',
        code: 'KY',
    },
    {
        name: 'KAMBODŻA',
        code: 'KH',
    },
    {
        name: 'KAMERUN',
        code: 'CM',
    },
    {
        name: 'KANADA',
        code: 'CA',
    },
    {
        name: 'KATAR',
        code: 'QA',
    },
    {
        name: 'KAZACHSTAN',
        code: 'KZ',
    },
    {
        name: 'KENIA',
        code: 'KE',
    },
    {
        name: 'KIRGISTAN',
        code: 'KG',
    },
    {
        name: 'KIRIBATI',
        code: 'KI',
    },
    {
        name: 'KOLUMBIA',
        code: 'CO',
    },
    {
        name: 'KOMORY',
        code: 'KM',
    },
    {
        name: 'KONGO',
        code: 'CG',
    },
    {
        name: 'KONGO, REPUBLIKA DEMOKRATYCZNA',
        code: 'CD',
    },
    {
        name: 'KOREAŃSKA REPUBLIKA LUDOWO-DEMOKRATYCZNA',
        code: 'KP',
    },
    {
        name: 'KOSOWO',
        code: 'XK',
    },
    {
        name: 'KOSTARYKA',
        code: 'CR',
    },
    {
        name: 'KUBA',
        code: 'CU',
    },
    {
        name: 'KUWEJT',
        code: 'KW',
    },
    {
        name: 'LAOS',
        code: 'LA',
    },
    {
        name: 'LESOTHO',
        code: 'LS',
    },
    {
        name: 'LIBAN',
        code: 'LB',
    },
    {
        name: 'LIBERIA',
        code: 'LR',
    },
    {
        name: 'LIBIA',
        code: 'LY',
    },
    {
        name: 'LIECHTENSTEIN',
        code: 'LI',
    },
    {
        name: 'LITWA',
        code: 'LT',
    },
    {
        name: 'ŁOTWA',
        code: 'LV',
    },
    {
        name: 'LUKSEMBURG',
        code: 'LU',
    },
    {
        name: 'REPUBLIKA MACEDONII PÓŁNOCNEJ',
        code: 'MK',
    },
    {
        name: 'MADAGASKAR',
        code: 'MG',
    },
    {
        name: 'MAJOTTA',
        code: 'YT',
    },
    {
        name: 'MAKAO',
        code: 'MO',
    },
    {
        name: 'MALAWI',
        code: 'MW',
    },
    {
        name: 'MALEDIWY',
        code: 'MV',
    },
    {
        name: 'MALEZJA',
        code: 'MY',
    },
    {
        name: 'MALI',
        code: 'ML',
    },
    {
        name: 'MALTA',
        code: 'MT',
    },
    {
        name: 'MARIANY PÓŁNOCNE',
        code: 'MP',
    },
    {
        name: 'MAROKO',
        code: 'MA',
    },
    {
        name: 'MARTYNIKA',
        code: 'MQ',
    },
    {
        name: 'MAURETANIA',
        code: 'MR',
    },
    {
        name: 'MAURITIUS',
        code: 'MU',
    },
    {
        name: 'MEKSYK',
        code: 'MX',
    },
    {
        name: 'MELILLA',
        code: 'XL',
    },
    {
        name: 'MIKRONEZJA',
        code: 'FM',
    },
    {
        name: 'MINOR',
        code: 'UM',
    },
    {
        name: 'MOŁDOWA',
        code: 'MD',
    },
    {
        name: 'MONAKO',
        code: 'MC',
    },
    {
        name: 'MONGOLIA',
        code: 'MN',
    },
    {
        name: 'MONTSERRAT',
        code: 'MS',
    },
    {
        name: 'MOZAMBIK',
        code: 'MZ',
    },
    {
        name: 'MYANMAR (BURMA)',
        code: 'MM',
    },
    {
        name: 'NAMIBIA',
        code: 'NA',
    },
    {
        name: 'NAURU',
        code: 'NR',
    },
    {
        name: 'NEPAL',
        code: 'NP',
    },
    {
        name: 'NIEMCY',
        code: 'DE',
    },
    {
        name: 'NIGER',
        code: 'NE',
    },
    {
        name: 'NIGERIA',
        code: 'NG',
    },
    {
        name: 'NIKARAGUA',
        code: 'NI',
    },
    {
        name: 'NIUE',
        code: 'NU',
    },
    {
        name: 'NORFOLK',
        code: 'NF',
    },
    {
        name: 'NORWEGIA',
        code: 'NO',
    },
    {
        name: 'NOWA KALEDONIA',
        code: 'NC',
    },
    {
        name: 'NOWA ZELANDIA',
        code: 'NZ',
    },
    {
        name: 'OKUPOWANE TERYTORIUM PALESTYNY',
        code: 'PS',
    },
    {
        name: 'OMAN',
        code: 'OM',
    },
    {
        name: 'PAKISTAN',
        code: 'PK',
    },
    {
        name: 'PALAU',
        code: 'PW',
    },
    {
        name: 'PANAMA',
        code: 'PA',
    },
    {
        name: 'PAPUA NOWA GWINEA',
        code: 'PG',
    },
    {
        name: 'PARAGWAJ',
        code: 'PY',
    },
    {
        name: 'PERU',
        code: 'PE',
    },
    {
        name: 'PITCAIRN',
        code: 'PN',
    },
    {
        name: 'POLINEZJA FRANCUSKA',
        code: 'PF',
    },
    {
        name: 'POLSKA',
        code: 'PL',
    },
    {
        name: 'POŁUDNIOWA GEORGIA I POŁUD.WYSPY SANDWICH',
        code: 'GS',
    },
    {
        name: 'PORTUGALIA',
        code: 'PT',
    },
    {
        name: 'PUERTO RICO',
        code: 'PR',
    },
    {
        name: 'REP.ŚRODKOWOAFRYKAŃSKA',
        code: 'CF',
    },
    {
        name: 'REPUBLIKA CZESKA',
        code: 'CZ',
    },
    {
        name: 'REPUBLIKA KOREI',
        code: 'KR',
    },
    {
        name: 'REPUBLIKA POŁUDNIOWEJ AFRYKI',
        code: 'ZA',
    },
    {
        name: 'REUNION',
        code: 'RE',
    },
    {
        name: 'ROSJA',
        code: 'RU',
    },
    {
        name: 'RUMUNIA',
        code: 'RO',
    },
    {
        name: 'RWANDA',
        code: 'RW',
    },
    {
        name: 'SAHARA ZACHODNIA',
        code: 'EH',
    },
    {
        name: 'SAINT BARTHELEMY',
        code: 'BL',
    },
    {
        name: 'SAINT KITTS I NEVIS',
        code: 'KN',
    },
    {
        name: 'SAINT LUCIA',
        code: 'LC',
    },
    {
        name: 'SAINT MARTIN',
        code: 'MF',
    },
    {
        name: 'SAINT VINCENT I GRENADYNY',
        code: 'VC',
    },
    {
        name: 'SALWADOR',
        code: 'SV',
    },
    {
        name: 'SAMOA',
        code: 'WS',
    },
    {
        name: 'SAMOA AMERYKAŃSKIE',
        code: 'AS',
    },
    {
        name: 'SAN MARINO',
        code: 'SM',
    },
    {
        name: 'SENEGAL',
        code: 'SN',
    },
    {
        name: 'SERBIA',
        code: 'RS',
    },
    {
        name: 'SESZELE',
        code: 'SC',
    },
    {
        name: 'SIERRA LEONE',
        code: 'SL',
    },
    {
        name: 'SINGAPUR',
        code: 'SG',
    },
    {
        name: 'SŁOWACJA',
        code: 'SK',
    },
    {
        name: 'SŁOWENIA',
        code: 'SI',
    },
    {
        name: 'SOMALIA',
        code: 'SO',
    },
    {
        name: 'SRI LANKA',
        code: 'LK',
    },
    {
        name: 'ST. PIERRE I MIQUELON',
        code: 'PM',
    },
    {
        name: 'STANY ZJEDNOCZONE AMERYKI',
        code: 'US',
    },
    {
        name: 'SUAZI',
        code: 'SZ',
    },
    {
        name: 'SUDAN',
        code: 'SD',
    },
    {
        name: 'SURINAM',
        code: 'SR',
    },
    {
        name: 'SVALBARD I JAN MAYEN',
        code: 'SJ',
    },
    {
        name: 'ŚWIĘTA HELENA',
        code: 'SH',
    },
    {
        name: 'SYRIA',
        code: 'SY',
    },
    {
        name: 'SZWAJCARIA',
        code: 'CH',
    },
    {
        name: 'SZWECJA',
        code: 'SE',
    },
    {
        name: 'TADŻYKISTAN',
        code: 'TJ',
    },
    {
        name: 'TAJLANDIA',
        code: 'TH',
    },
    {
        name: 'TAJWAN',
        code: 'TW',
    },
    {
        name: 'TANZANIA',
        code: 'TZ',
    },
    {
        name: 'TOGO',
        code: 'TG',
    },
    {
        name: 'TOKELAU',
        code: 'TK',
    },
    {
        name: 'TONGA',
        code: 'TO',
    },
    {
        name: 'TRYNIDAD I TOBAGO',
        code: 'TT',
    },
    {
        name: 'TUNEZJA',
        code: 'TN',
    },
    {
        name: 'TURCJA',
        code: 'TR',
    },
    {
        name: 'TURKMENISTAN',
        code: 'TM',
    },
    {
        name: 'TUVALU',
        code: 'TV',
    },
    {
        name: 'UGANDA',
        code: 'UG',
    },
    {
        name: 'UKRAINA',
        code: 'UA',
    },
    {
        name: 'URUGWAJ',
        code: 'UY',
    },
    {
        name: 'UZBEKISTAN',
        code: 'UZ',
    },
    {
        name: 'VANUATU',
        code: 'VU',
    },
    {
        name: 'WALLIS I FUTUNA',
        code: 'WF',
    },
    {
        name: 'WATYKAN',
        code: 'VA',
    },
    {
        name: 'WĘGRY',
        code: 'HU',
    },
    {
        name: 'WENEZUELA',
        code: 'VE',
    },
    {
        name: 'WIELKA BRYTANIA',
        code: 'GB',
    },
    {
        name: 'WIETNAM',
        code: 'VN',
    },
    {
        name: 'WŁOCHY',
        code: 'IT',
    },
    {
        name: 'WSCHODNI TIMOR',
        code: 'TL',
    },
    {
        name: 'WYBRZEŻE KOŚCI SŁONIOWEJ',
        code: 'CI',
    },
    {
        name: 'WYSPA BOUVETA',
        code: 'BV',
    },
    {
        name: 'WYSPA BOŻEGO NARODZENIA',
        code: 'CX',
    },
    {
        name: 'WYSPA MAN',
        code: 'IM',
    },
    {
        name: 'WYSPY COOKA',
        code: 'CK',
    },
    {
        name: 'WYSPY DZIEWICZE-USA',
        code: 'VI',
    },
    {
        name: 'WYSPY DZIEWICZE-W.B.',
        code: 'VG',
    },
    {
        name: 'WYSPY HEARD I MCDONALD',
        code: 'HM',
    },
    {
        name: 'WYSPY KOKOSOWE (KEELINGA)',
        code: 'CC',
    },
    {
        name: 'WYSPY MARSHALLA',
        code: 'MH',
    },
    {
        name: 'WYSPY OWCZE',
        code: 'FO',
    },
    {
        name: 'WYSPY SALOMONA',
        code: 'SB',
    },
    {
        name: 'WYSPY ŚWIĘTEGO TOMASZA I KSIĄŻĘCA',
        code: 'ST',
    },
    {
        name: 'WYSPY TURKS I CAICOS',
        code: 'TC',
    },
    {
        name: 'ZAMBIA',
        code: 'ZM',
    },
    {
        name: 'ZIELONY PRZYLĄDEK',
        code: 'CV',
    },
    {
        name: 'ZIMBABWE',
        code: 'ZW',
    },
    {
        name: 'ZJEDNOCZONE EMIRATY ARABSKIE',
        code: 'AE',
    },
    {
        name: 'IRLANDIA PÓŁNOCNA',
        code: 'XI',

    }
]