<div class="container-fluid">

  <div class="m-client-confirmation">

    <div class="row">
      <div class="col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">

        <div class="m-success-info">
          <div class="m-logo">
            <img src="/assets/img/migam_logo.svg" alt="">
          </div>
          <p *ngIf="isProcessing">{{ 'client_confirmation.loading_info' | translate }}</p>
          <p *ngIf="!isProcessing">{{ confirmationResultMsg }}</p>
        </div>

      </div>
    </div>

  </div>
</div>