import { SignupRequestPayloadWithRecaptchaToken, CloudFunctionResponse } from './../../../../shared/model/saas.interface';
import { Router } from '@angular/router';
import { countryDictionary } from './../../../../shared/model/country-dictionary';
import { Country } from './../../../../shared/model/country.interface';
import { TranslateService } from '@ngx-translate/core';
import { environment } from './../../environments/environment';
import { SignupRequestPayload } from '../../../../shared/model/saas.interface';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { catchError, map, take } from 'rxjs/operators';
import { RecaptchaComponent, RecaptchaErrorParameters } from 'ng-recaptcha';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Component({
  selector: 'app-signup-form',
  templateUrl: './signup-form.component.html',
  styleUrls: ['./signup-form.component.scss']
})
export class SignupFormComponent implements OnInit {

  signupForm: FormGroup;
  isSubmitProcessing: boolean = false;

  isDifferentServiceDeliveryAddressAsCompanyAddress = false;

  isDifferentWorkingHoursDuringWorkdays = false;
  defaultOpenTime = '08:00';
  defaultCloseTime = '16:00';
  // productTitle = 'Usługa Tłumacza Migam (pakiet START)';
  // productPrice = '39,9';
  // productCurrency = ProductCurrency.PLN;

  // apiToken = environment.apiToken;

  reCaptchaSiteKey = environment.reCaptchaSiteKey;
  reCatpchaToken: string;
  @ViewChild(RecaptchaComponent) reCaptchaRef: RecaptchaComponent;

  isSubmitFormError: boolean = false;
  submitFormErrorMsg: string;

  zipCodeRegex = /^[0-9]{2}-[0-9]{3}$/;

  countries: Country[] = countryDictionary;

  openTimes = [
    '08:00',
    '08:30',
    '09:00',
    '09:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    // '16:00',
    // '16:30',
    // '17:00',
    // '17:30',
    // '18:00',
    // '18:30',
    // '19:00',
    // '19:30',
  ];

  closeTimes = [
    '08:30',
    '09:00',
    '09:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    // '16:30',
    // '17:00',
    // '17:30',
    // '18:00',
    // '18:30',
    // '19:00',
    // '19:30',
    // '20:00',
  ];

  constructor(
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private router: Router,
    private firebaseFunctions: AngularFireFunctions,
  ) { }

  ngOnInit() {
    this.createSignupForm();
  }

  createSignupForm() {
    this.signupForm = this.formBuilder.group({

      personFirstName: ['', Validators.required],
      personLastName: ['', Validators.required],
      personEmail: ['', [Validators.required, Validators.email]],
      personPhone: ['', Validators.required],

      companyName: ['', Validators.required],
      companyNip: ['', [Validators.required, this.nipValidator]],
      companyStreet: ['', Validators.required],
      companyZip: ['', [Validators.required, Validators.pattern(this.zipCodeRegex)]],
      companyCity: ['', Validators.required],
      companyCountryCode: ['PL', Validators.required],

      differentDeliveryAddressAsCompanyAddress: [this.isDifferentServiceDeliveryAddressAsCompanyAddress, Validators.required],
      serviceDeliveryName: [
        {
          value: '',
          disabled: !this.isDifferentServiceDeliveryAddressAsCompanyAddress
        },
        Validators.required
      ],
      serviceDeliveryNip: [
        {
          value: '',
          disabled: !this.isDifferentServiceDeliveryAddressAsCompanyAddress
        },
        // [Validators.required, this.nipValidator]
        this.nipValidator

      ],
      serviceDeliveryStreet: [
        {
          value: '',
          disabled: !this.isDifferentServiceDeliveryAddressAsCompanyAddress
        },
        Validators.required
      ],
      serviceDeliveryZip: [
        {
          value: '',
          disabled: !this.isDifferentServiceDeliveryAddressAsCompanyAddress
        },
        [Validators.required, Validators.pattern(this.zipCodeRegex)]
      ],
      serviceDeliveryCity: [
        {
          value: '',
          disabled: !this.isDifferentServiceDeliveryAddressAsCompanyAddress
        },
        Validators.required
      ],
      serviceDeliveryCountryCode: [
        {
          value: 'PL',
          disabled: !this.isDifferentServiceDeliveryAddressAsCompanyAddress
        },
        Validators.required
      ],

      serviceDeliveryPhone: ['', Validators.required],

      differentWorkingHoursDuringWorkdays: [this.isDifferentWorkingHoursDuringWorkdays, Validators.required],
      weekdayIsOpened: [
        {
          value: this.isDifferentWorkingHoursDuringWorkdays ? false : true,
          disabled: this.isDifferentWorkingHoursDuringWorkdays
        },
        Validators.required
      ],
      weekdayOpenTime: [
        {
          value: this.isDifferentWorkingHoursDuringWorkdays ? '' : this.defaultOpenTime,
          disabled: this.isDifferentWorkingHoursDuringWorkdays
        },
        Validators.required
      ],
      weekdayCloseTime: [
        {
          value: this.isDifferentWorkingHoursDuringWorkdays ? '' : this.defaultCloseTime,
          disabled: this.isDifferentWorkingHoursDuringWorkdays
        },
        Validators.required
      ],

      mondayIsOpened: [
        {
          value: this.isDifferentWorkingHoursDuringWorkdays ? true : false,
          disabled: !this.isDifferentWorkingHoursDuringWorkdays
        },
        Validators.required
      ],
      mondayOpenTime: [
        {
          value: this.isDifferentWorkingHoursDuringWorkdays ? this.defaultOpenTime : '',
          disabled: !this.isDifferentWorkingHoursDuringWorkdays
        },
        Validators.required
      ],
      mondayCloseTime: [
        {
          value: this.isDifferentWorkingHoursDuringWorkdays ? this.defaultCloseTime : '',
          disabled: !this.isDifferentWorkingHoursDuringWorkdays
        },
        Validators.required
      ],

      tuesdayIsOpened: [
        {
          value: this.isDifferentWorkingHoursDuringWorkdays ? true : false,
          disabled: !this.isDifferentWorkingHoursDuringWorkdays
        },
        Validators.required
      ],
      tuesdayOpenTime: [
        {
          value: this.isDifferentWorkingHoursDuringWorkdays ? this.defaultOpenTime : '',
          disabled: !this.isDifferentWorkingHoursDuringWorkdays
        },
        Validators.required
      ],
      tuesdayCloseTime: [
        {
          value: this.isDifferentWorkingHoursDuringWorkdays ? this.defaultCloseTime : '',
          disabled: !this.isDifferentWorkingHoursDuringWorkdays
        },
        Validators.required
      ],

      wednesdayIsOpened: [
        {
          value: this.isDifferentWorkingHoursDuringWorkdays ? true : false,
          disabled: !this.isDifferentWorkingHoursDuringWorkdays
        },
        Validators.required
      ],
      wednesdayOpenTime: [
        {
          value: this.isDifferentWorkingHoursDuringWorkdays ? this.defaultOpenTime : '',
          disabled: !this.isDifferentWorkingHoursDuringWorkdays
        },
        Validators.required
      ],
      wednesdayCloseTime: [
        {
          value: this.isDifferentWorkingHoursDuringWorkdays ? this.defaultCloseTime : '',
          disabled: !this.isDifferentWorkingHoursDuringWorkdays
        },
        Validators.required
      ],

      thursdayIsOpened: [
        {
          value: this.isDifferentWorkingHoursDuringWorkdays ? true : false,
          disabled: !this.isDifferentWorkingHoursDuringWorkdays
        },
        Validators.required
      ],
      thursdayOpenTime: [
        {
          value: this.isDifferentWorkingHoursDuringWorkdays ? this.defaultOpenTime : '',
          disabled: !this.isDifferentWorkingHoursDuringWorkdays
        },
        Validators.required
      ],
      thursdayCloseTime: [
        {
          value: this.isDifferentWorkingHoursDuringWorkdays ? this.defaultCloseTime : '',
          disabled: !this.isDifferentWorkingHoursDuringWorkdays
        },
        Validators.required
      ],

      fridayIsOpened: [
        {
          value: this.isDifferentWorkingHoursDuringWorkdays ? true : false,
          disabled: !this.isDifferentWorkingHoursDuringWorkdays
        },
        Validators.required
      ],
      fridayOpenTime: [
        {
          value: this.isDifferentWorkingHoursDuringWorkdays ? this.defaultOpenTime : '',
          disabled: !this.isDifferentWorkingHoursDuringWorkdays
        },
        Validators.required
      ],
      fridayCloseTime: [
        {
          value: this.isDifferentWorkingHoursDuringWorkdays ? this.defaultCloseTime : '',
          disabled: !this.isDifferentWorkingHoursDuringWorkdays
        },
        Validators.required
      ],

      saturdayIsOpened: [false, Validators.required],
      saturdayOpenTime: [
        {
          value: '',
          disabled: true
        },
        Validators.required
      ],
      saturdayCloseTime: [
        {
          value: '',
          disabled: true
        },
        Validators.required
      ],
      sundayIsOpened: [false, Validators.required],
      sundayOpenTime: [
        {
          value: '',
          disabled: true
        },
        Validators.required
      ],
      sundayCloseTime: [
        {
          value: '',
          disabled: true
        },
        Validators.required
      ]

    });
  }

  onSubmitButtonClick() {
    // Disable submit button
    this.isSubmitProcessing = true;

    // Run reCaptcha to get captcha token (when token is received onReCaptchaTokenResolved will run)
    this.reCaptchaRef.reset();
    this.reCaptchaRef.execute();
  }

  onReCaptchaTokenResolved(captchaResponse: string) {
    // console.log('Captcha response: ', captchaResponse);
    this.reCatpchaToken = captchaResponse;
    if (this.reCatpchaToken) {
      this.submitForm();
    }
  }

  onReCaptchaTokenError(errorDetails: RecaptchaErrorParameters) {
    console.error(`reCaptcha error encountered:`, errorDetails);
    this.setSubmitFormError(this.translate.instant('signup_form.errors.failed_to_generate_captcha_token'));
  }

  setSubmitFormError(errorDescription: string) {
    this.isSubmitProcessing = false;
    this.isSubmitFormError = true;
    this.submitFormErrorMsg = this.translate.instant('signup_form.error_title') + ' ' + errorDescription + ' ' + this.translate.instant('signup_form.error_contact_info');
  }

  submitForm() {

    // Create payload
    const signupRequestPayload: SignupRequestPayload = {
      person_first_name: this.signupForm.get('personFirstName').value,
      person_last_name: this.signupForm.get('personLastName').value,
      person_email: this.signupForm.get('personEmail').value,
      person_phone: this.signupForm.get('personPhone').value,

      company_name: this.signupForm.get('companyName').value,
      company_nip: this.trimCharactersInNip(this.signupForm.get('companyNip').value), // trim characters
      company_street: this.signupForm.get('companyStreet').value,
      company_zip: this.signupForm.get('companyZip').value,
      company_city: this.signupForm.get('companyCity').value,
      company_country_code: this.signupForm.get('companyCountryCode').value,

      // isDifferentServiceDeliveryCompany: this.isDifferentServiceDeliveryAddressAsCompanyAddress, 

      // service_delivery_name: this.isDifferentServiceDeliveryAddressAsCompanyAddress ? this.signupForm.get('serviceDeliveryName').value : this.signupForm.get('companyName').value,
      // service_delivery_nip: this.isDifferentServiceDeliveryAddressAsCompanyAddress ? this.trimCharactersInNip(this.signupForm.get('serviceDeliveryNip').value) : this.trimCharactersInNip(this.signupForm.get('companyNip').value),
      // service_delivery_street: this.isDifferentServiceDeliveryAddressAsCompanyAddress ? this.signupForm.get('serviceDeliveryStreet').value : this.signupForm.get('companyStreet').value,
      // service_delivery_zip: this.isDifferentServiceDeliveryAddressAsCompanyAddress ? this.signupForm.get('serviceDeliveryZip').value : this.signupForm.get('companyZip').value,
      // service_delivery_city: this.isDifferentServiceDeliveryAddressAsCompanyAddress ? this.signupForm.get('serviceDeliveryCity').value : this.signupForm.get('companyCity').value,
      // service_delivery_country_code: this.isDifferentServiceDeliveryAddressAsCompanyAddress ? this.signupForm.get('serviceDeliveryCountryCode').value : this.signupForm.get('companyCountryCode').value,

      service_delivery_name: this.isDifferentServiceDeliveryAddressAsCompanyAddress ? this.signupForm.get('serviceDeliveryName').value : '',
      service_delivery_nip: this.isDifferentServiceDeliveryAddressAsCompanyAddress ? this.trimCharactersInNip(this.signupForm.get('serviceDeliveryNip').value) : '',
      service_delivery_street: this.isDifferentServiceDeliveryAddressAsCompanyAddress ? this.signupForm.get('serviceDeliveryStreet').value : '',
      service_delivery_zip: this.isDifferentServiceDeliveryAddressAsCompanyAddress ? this.signupForm.get('serviceDeliveryZip').value : '',
      service_delivery_city: this.isDifferentServiceDeliveryAddressAsCompanyAddress ? this.signupForm.get('serviceDeliveryCity').value : '',
      service_delivery_country_code: this.isDifferentServiceDeliveryAddressAsCompanyAddress ? this.signupForm.get('serviceDeliveryCountryCode').value : '',

      service_delivery_phone: this.signupForm.get('serviceDeliveryPhone').value,

      opening_hours: {
        monday: {
          is_opened: !this.isDifferentWorkingHoursDuringWorkdays ? this.signupForm.get('weekdayIsOpened').value : this.signupForm.get('mondayIsOpened').value,
          open_time: !this.isDifferentWorkingHoursDuringWorkdays ? this.signupForm.get('weekdayOpenTime').value : this.signupForm.get('mondayOpenTime').value,
          close_time: !this.isDifferentWorkingHoursDuringWorkdays ? this.signupForm.get('weekdayCloseTime').value : this.signupForm.get('mondayCloseTime').value
        },
        tuesday: {
          is_opened: !this.isDifferentWorkingHoursDuringWorkdays ? this.signupForm.get('weekdayIsOpened').value : this.signupForm.get('tuesdayIsOpened').value,
          open_time: !this.isDifferentWorkingHoursDuringWorkdays ? this.signupForm.get('weekdayOpenTime').value : this.signupForm.get('tuesdayOpenTime').value,
          close_time: !this.isDifferentWorkingHoursDuringWorkdays ? this.signupForm.get('weekdayCloseTime').value : this.signupForm.get('tuesdayCloseTime').value
        },
        wednesday: {
          is_opened: !this.isDifferentWorkingHoursDuringWorkdays ? this.signupForm.get('weekdayIsOpened').value : this.signupForm.get('wednesdayIsOpened').value,
          open_time: !this.isDifferentWorkingHoursDuringWorkdays ? this.signupForm.get('weekdayOpenTime').value : this.signupForm.get('wednesdayOpenTime').value,
          close_time: !this.isDifferentWorkingHoursDuringWorkdays ? this.signupForm.get('weekdayCloseTime').value : this.signupForm.get('wednesdayCloseTime').value
        },
        thursday: {
          is_opened: !this.isDifferentWorkingHoursDuringWorkdays ? this.signupForm.get('weekdayIsOpened').value : this.signupForm.get('thursdayIsOpened').value,
          open_time: !this.isDifferentWorkingHoursDuringWorkdays ? this.signupForm.get('weekdayOpenTime').value : this.signupForm.get('thursdayOpenTime').value,
          close_time: !this.isDifferentWorkingHoursDuringWorkdays ? this.signupForm.get('weekdayCloseTime').value : this.signupForm.get('thursdayCloseTime').value
        },
        friday: {
          is_opened: !this.isDifferentWorkingHoursDuringWorkdays ? this.signupForm.get('weekdayIsOpened').value : this.signupForm.get('fridayIsOpened').value,
          open_time: !this.isDifferentWorkingHoursDuringWorkdays ? this.signupForm.get('weekdayOpenTime').value : this.signupForm.get('fridayOpenTime').value,
          close_time: !this.isDifferentWorkingHoursDuringWorkdays ? this.signupForm.get('weekdayCloseTime').value : this.signupForm.get('fridayCloseTime').value
        },
        saturday: {
          is_opened: this.signupForm.get('saturdayIsOpened').value,
          open_time: this.signupForm.get('saturdayOpenTime').value,
          close_time: this.signupForm.get('saturdayCloseTime').value
        },
        sunday: {
          is_opened: this.signupForm.get('sundayIsOpened').value,
          open_time: this.signupForm.get('sundayOpenTime').value,
          close_time: this.signupForm.get('sundayCloseTime').value
        }
      },

      // product_title: this.productTitle,
      // product_price: this.productPrice,
      // product_currency: this.productCurrency,
    }

    // console.log(signupRequestPayload);

    const payload: SignupRequestPayloadWithRecaptchaToken = {
      signupRequestPayload: signupRequestPayload,
      recaptchaToken: this.reCatpchaToken
    }
    
    const signupFunction = this.firebaseFunctions.httpsCallable('signup');
    signupFunction(payload).pipe(
      take(1),
      map((response: CloudFunctionResponse) => {

        if (response.success) {
          this.isSubmitProcessing = false;
          this.router.navigate(['/thank-you']);
        } else {
          switch (response.error_code) {
            case 'invalid_api_token':
              this.setSubmitFormError(this.translate.instant('signup_form.errors.invalid_api_token'));
              break;
            case 'invalid_captcha_token':
              this.setSubmitFormError(this.translate.instant('signup_form.errors.invalid_captcha_token'));
              break;
            case 'invalid_paylod':
              this.setSubmitFormError(this.translate.instant('signup_form.errors.invalid_captch_token'));
              break;
            case 'invalid_address':
              this.setSubmitFormError(this.translate.instant('signup_form.errors.invalid_address'));
              break;
            case 'internal_server_error':
              this.setSubmitFormError(this.translate.instant('signup_form.errors.internal_server_error'));
              break;
            case 'duplicate_company_name':
              this.setSubmitFormError(this.translate.instant('signup_form.errors.duplicate_company_name'));
              break;
            case 'duplicate_company_nip':
              this.setSubmitFormError(this.translate.instant('signup_form.errors.duplicate_company_nip'));
              break;
            default:
              this.setSubmitFormError(this.translate.instant('signup_form.errors.unknown_error'));
              break;
          }
        }
      }),
      catchError(error => {
        this.setSubmitFormError(this.translate.instant('signup_form.errors.unknown_error'));
        throw error;
      })
    ).subscribe();

    // // Set http params
    // let httpParams: HttpParams = new HttpParams();
    // // httpParams = httpParams.set('api_token', this.apiToken);
    // httpParams = httpParams.set('captcha_token', this.reCatpchaToken);

    // // Send paylod to api endpoint
    // this.httpClient.post(
    //   this.signupEndpointUrl,
    //   signupRequestPayload,
    //   {
    //     params: httpParams
    //   }
    // ).pipe(
    //   map(response => {
    //     this.isSubmitProcessing = false;
    //     this.router.navigate(['/thank-you']);
    //   }),
    //   catchError(error => {
    //     // console.error('Request failed', error)
    //     switch (error.error.error_code) {
    //       case 'invalid_api_token':
    //         this.setSubmitFormError(this.translate.instant('signup_form.errors.invalid_api_token'));
    //         break;
    //       case 'invalid_captcha_token':
    //         this.setSubmitFormError(this.translate.instant('signup_form.errors.invalid_captcha_token'));
    //         break;
    //       case 'invalid_paylod':
    //         this.setSubmitFormError(this.translate.instant('signup_form.errors.invalid_captch_token'));
    //         break;
    //       case 'invalid_address':
    //         this.setSubmitFormError(this.translate.instant('signup_form.errors.invalid_address'));
    //         break;
    //       case 'internal_server_error':
    //         this.setSubmitFormError(this.translate.instant('signup_form.errors.internal_server_error'));
    //         break;
    //       case 'duplicate_company_name':
    //         this.setSubmitFormError(this.translate.instant('signup_form.errors.duplicate_company_name'));
    //         break;
    //       case 'duplicate_company_nip':
    //         this.setSubmitFormError(this.translate.instant('signup_form.errors.duplicate_company_nip'));
    //         break;
    //       default:
    //         this.setSubmitFormError(this.translate.instant('signup_form.errors.unknown_error'));
    //         break;
    //     }
    //     throw error;
    //   })
    // ).subscribe();

  }

  toggleDifferentWorkingHoursDuringWorkdays(event: MatCheckboxChange) {
    if (event.checked) {
      this.isDifferentWorkingHoursDuringWorkdays = true;

      // Disable weekdays (single fileds for mon-friday)
      this.disableDayCheckbox('weekdayIsOpened');
      this.disableOpenTimeInput('weekdayOpenTime');
      this.disableCloseTimeInput('weekdayCloseTime');

      // Enable each day durig week (separe fields for each day)
      this.enableDayCheckbox('mondayIsOpened');
      this.enableOpenTimeInput('mondayOpenTime');
      this.enableCloseTimeInput('mondayCloseTime');

      this.enableDayCheckbox('tuesdayIsOpened');
      this.enableOpenTimeInput('tuesdayOpenTime');
      this.enableCloseTimeInput('tuesdayCloseTime');

      this.enableDayCheckbox('wednesdayIsOpened');
      this.enableOpenTimeInput('wednesdayOpenTime');
      this.enableCloseTimeInput('wednesdayCloseTime');

      this.enableDayCheckbox('thursdayIsOpened');
      this.enableOpenTimeInput('thursdayOpenTime');
      this.enableCloseTimeInput('thursdayCloseTime');

      this.enableDayCheckbox('fridayIsOpened');
      this.enableOpenTimeInput('fridayOpenTime');
      this.enableCloseTimeInput('fridayCloseTime');
    } else {
      this.isDifferentWorkingHoursDuringWorkdays = false;

      // Enable weekdays (single fileds for mon-friday)
      this.enableDayCheckbox('weekdayIsOpened');
      this.enableOpenTimeInput('weekdayOpenTime');
      this.enableCloseTimeInput('weekdayCloseTime');

      // Disable each day durig week (separe fields for each day)
      this.disableDayCheckbox('mondayIsOpened');
      this.disableOpenTimeInput('mondayOpenTime');
      this.disableCloseTimeInput('mondayCloseTime');

      this.disableDayCheckbox('tuesdayIsOpened');
      this.disableOpenTimeInput('tuesdayOpenTime');
      this.disableCloseTimeInput('tuesdayCloseTime');

      this.disableDayCheckbox('wednesdayIsOpened');
      this.disableOpenTimeInput('wednesdayOpenTime');
      this.disableCloseTimeInput('wednesdayCloseTime');

      this.disableDayCheckbox('thursdayIsOpened');
      this.disableOpenTimeInput('thursdayOpenTime');
      this.disableCloseTimeInput('thursdayCloseTime');

      this.disableDayCheckbox('fridayIsOpened');
      this.disableOpenTimeInput('fridayOpenTime');
      this.disableCloseTimeInput('fridayCloseTime');
    }
  }

  toggleOpenHoursDay(event: MatCheckboxChange, fieldName: string) {
    // Enable form controls
    if (event.checked) {

      switch (fieldName) {
        case 'weekdayIsOpened':
          this.enableOpenTimeInput('weekdayOpenTime');
          this.enableCloseTimeInput('weekdayCloseTime');
          break;
        case 'mondayIsOpened':
          this.enableOpenTimeInput('mondayOpenTime');
          this.enableCloseTimeInput('mondayCloseTime');
          break;
        case 'tuesdayIsOpened':
          this.enableOpenTimeInput('tuesdayOpenTime');
          this.enableCloseTimeInput('tuesdayCloseTime');
          break;
        case 'wednesdayIsOpened':
          this.enableOpenTimeInput('wednesdayOpenTime');
          this.enableCloseTimeInput('wednesdayCloseTime');
          break;
        case 'thursdayIsOpened':
          this.enableOpenTimeInput('thursdayOpenTime');
          this.enableCloseTimeInput('thursdayCloseTime');
          break;
        case 'fridayIsOpened':
          this.enableOpenTimeInput('fridayOpenTime');
          this.enableCloseTimeInput('fridayCloseTime');
          break;
        case 'saturdayIsOpened':
          this.enableOpenTimeInput('saturdayOpenTime');
          this.enableCloseTimeInput('saturdayCloseTime');
          break;
        case 'sundayIsOpened':
          this.enableOpenTimeInput('sundayOpenTime');
          this.enableCloseTimeInput('sundayCloseTime');
          break;
      }

    }
    // Disable form controls
    else {

      switch (fieldName) {
        case 'weekdayIsOpened':
          this.disableOpenTimeInput('weekdayOpenTime');
          this.disableCloseTimeInput('weekdayCloseTime');
          break;
        case 'mondayIsOpened':
          this.disableOpenTimeInput('mondayOpenTime');
          this.disableCloseTimeInput('mondayCloseTime');
          break;
        case 'tuesdayIsOpened':
          this.disableOpenTimeInput('tuesdayOpenTime');
          this.disableCloseTimeInput('tuesdayCloseTime');
          break;
        case 'wednesdayIsOpened':
          this.disableOpenTimeInput('wednesdayOpenTime');
          this.disableCloseTimeInput('wednesdayCloseTime');
          break;
        case 'thursdayIsOpened':
          this.disableOpenTimeInput('thursdayOpenTime');
          this.disableCloseTimeInput('thursdayCloseTime');
          break;
        case 'fridayIsOpened':
          this.disableOpenTimeInput('fridayOpenTime');
          this.disableCloseTimeInput('fridayCloseTime');
          break;
        case 'saturdayIsOpened':
          this.disableOpenTimeInput('saturdayOpenTime');
          this.disableCloseTimeInput('saturdayCloseTime');
          break;
        case 'sundayIsOpened':
          this.disableOpenTimeInput('sundayOpenTime');
          this.disableCloseTimeInput('sundayCloseTime');
          break;
      }
    }
  }

  enableDayCheckbox(fieldName: string) {
    this.signupForm.get(fieldName).enable();
    this.signupForm.get(fieldName).setValue(true);
  }
  disableDayCheckbox(fieldName: string) {
    this.signupForm.get(fieldName).disable();
    this.signupForm.get(fieldName).setValue(false);
  }
  enableOpenTimeInput(fieldName: string) {
    this.signupForm.get(fieldName).enable();
    this.signupForm.get(fieldName).setValue(this.defaultOpenTime);
  }
  enableCloseTimeInput(fieldName: string) {
    this.signupForm.get(fieldName).enable();
    this.signupForm.get(fieldName).setValue(this.defaultCloseTime);
  }
  disableOpenTimeInput(fieldName: string) {
    this.signupForm.get(fieldName).disable();
    this.signupForm.get(fieldName).setValue('');
  }
  disableCloseTimeInput(fieldName: string) {
    this.signupForm.get(fieldName).disable();
    this.signupForm.get(fieldName).setValue('');
  }



  // Custom nip validator
  nipValidator(control: AbstractControl): { [key: string]: boolean } | null {

    let isNipValid: boolean;
    let nip = control.value;

    // Trim characters
    nip = nip.replace(/[\ \-]/gi, '');

    // Validate only if not empty
    if (nip.length > 0) {

      // Check length
      if (nip.length > 10) {
        isNipValid = false;
      }
      // Check checksum (https://www.modestprogrammer.pl/walidacja-nip-w-javascript)
      else {
        let weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
        let sum = 0;
        let controlNumber = parseInt(nip.substring(9, 10));
        let weightCount = weight.length;
        for (let i = 0; i < weightCount; i++) {
          sum += (parseInt(nip.substr(i, 1)) * weight[i]);
        }

        if (sum % 11 === controlNumber) {
          isNipValid = true;
        } else {
          isNipValid = false;
        }
      }

    } else {
      isNipValid = true;
    }

    if (!isNipValid) {
      return { 'nip': true }
    }
    return null;
  };

  // Trim characters helper function
  trimCharactersInNip(nip: string) {
    return nip.replace(/[\ \-]/gi, '');
  }



  toggleDifferentServiceDeliveryAddressAsCompanyAddress(event: MatCheckboxChange) {
    if (event.checked) {
      this.isDifferentServiceDeliveryAddressAsCompanyAddress = true;

      this.signupForm.get('serviceDeliveryName').enable();
      this.signupForm.get('serviceDeliveryNip').enable();
      this.signupForm.get('serviceDeliveryStreet').enable();
      this.signupForm.get('serviceDeliveryZip').enable();
      this.signupForm.get('serviceDeliveryCity').enable();
      this.signupForm.get('serviceDeliveryCountryCode').enable();

    } else {
      this.isDifferentServiceDeliveryAddressAsCompanyAddress = false;

      this.signupForm.get('serviceDeliveryName').disable();
      this.signupForm.get('serviceDeliveryNip').disable();
      this.signupForm.get('serviceDeliveryStreet').disable();
      this.signupForm.get('serviceDeliveryZip').disable();
      this.signupForm.get('serviceDeliveryCity').disable();
      this.signupForm.get('serviceDeliveryCountryCode').disable();

    }
  }


}
