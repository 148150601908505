<div class="container-fluid">

  <div class="m-offer-form">

    <div class="row">
      <div class="col-lg-8 offset-lg-2">

        <div class="m-logo">
          <img src="/assets/img/migam_logo.svg" alt="">
        </div>

        <div class="m-info" *ngIf="isOfferLoading">
          <mat-spinner diameter="50"></mat-spinner>
          <p>
            Wczytywanie Formularza Zamówienia...
          </p>
        </div>

        <div class="m-info" *ngIf="isOfferLoadingError">
          {{offerLoadingErrorMsg}}
        </div>

        <div *ngIf="!isOfferLoading && offer">

          <div class="row">
            <div class="col">
              <div class="m-form-title">
                <h1>Formularz Zamówienia</h1>
              </div>
            </div>
          </div>

          <div class="row">

            <div class="col">
              <div class="m-section">
                <div class="m-section-title">Oferta dla</div>
                {{offer.clientContactPerson.firstName}} {{offer.clientContactPerson.lastName}} <br>
                email: <a href="mailto:{{offer.clientContactPerson.email}}">{{offer.clientContactPerson.email}}</a> <br>
                {{offer.buyerOrganization.name}} <br>
              </div>
            </div>

            <div class="col">
              <div class="m-section">
                <div class="m-section-title">Opiekun z Migam</div>
                {{offer.sellerContactPerson.name}} <br>
                telefon:
                <a href="tel:{{offer.sellerContactPerson.phone}}">{{offer.sellerContactPerson.phone}}</a> <br>
                email: 
                <a href="mailto:{{offer.sellerContactPerson.email}}?subject=Pytanie o ofertę">{{offer.sellerContactPerson.email}}</a>
              </div>
            </div>
          </div>


          <div class="row">
            <div class="col">
              <div class="m-section">
                <div class="m-section-title">Usługi</div>

                <p *ngIf="offerNonSubscriptionProducts.length > 0"><strong>Jednorazowe:</strong></p>
                <p class="col" mat-menu-item class="m-queue-file-list-item"
                  *ngFor="let product of offerNonSubscriptionProducts">
                  {{product.name}} {{product.quantity}} x {{product.price}} = {{product.quantity * product.price | number: '1.1-2':'pl'}} PLN
                  netto + 23% VAT
                </p>

                <p *ngIf="offerSubscriptionProducts.length > 0"><strong>Abonamentowe:</strong></p>
                <div *ngIf="offer.isPerpetualContract">
                  <p class="col" mat-menu-item class="m-queue-file-list-item"
                    *ngFor="let product of offerSubscriptionProducts">
                    {{product.name}} {{product.price}} PLN
                    netto + 23% VAT miesięcznie
                  </p>
                </div>
                <div *ngIf="!offer.isPerpetualContract">
                  <p class="col" mat-menu-item class="m-queue-file-list-item"
                    *ngFor="let product of offerSubscriptionProducts">
                    {{product.name}} {{product.quantity}} x {{product.price}} = {{product.quantity * product.price | number: '1.1-2':'pl'}} PLN
                    netto + 23% VAT
                  </p>
                </div>

                <p
                  *ngIf="!offer.isPerpetualContract && (offerNonSubscriptionProducts.length + offerSubscriptionProducts.length) > 1">
                  <strong>Razem:</strong> {{offerSubscriptionProductsTotalNetPrice +
                  offerNonSubscriptionProductsTotalNetPrice | number: '1.1-2':'pl'}} PLN netto + 23% VAT
                </p>

              </div>
            </div>

          </div>

          <div class="row" *ngIf="offer.additionalInfoForClient">
            <div class="col">
              <div class="m-section">
                <div class="m-section-title">Dodatkowe informacje</div>
                <p>
                  {{offer.additionalInfoForClient}}
                </p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="m-section">
                <div class="m-section-title">Parametry umowy</div>
                <ng-container *ngIf="offer.isOfferForSubscriptionProduct">
                  <p>
                    <strong>Okres obowiązywania umowy: </strong>
                    <span *ngIf="!offer.isPerpetualContract">Umowa na czas określony, liczba miesięcy:
                      {{offer.contractDurationInMonths}}</span>
                    <span *ngIf="offer.isPerpetualContract">Umowa na czas nieokreślony z
                      {{offer.contractTerminationPeriodInMonths}} miesięcznym okresem wypowiedzenia</span>
                  </p>
                  <p *ngIf="offer.paymentIntervalInMonths > 0">
                    <strong>Faktura za abonament będzie wystawiana co (liczba miesięcy): </strong>
                    {{offer.paymentIntervalInMonths}}M
                  </p>
                </ng-container>
                <p><strong>Płatność z góry: </strong>{{offer.isPaymentInAdvance ? 'tak' : 'nie'}}</p>
                <p><strong>Termin płatności faktury (liczba dni): </strong>{{offer.invoicePaymentDays}}</p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="m-section">
                <div class="m-section-title">Dane płatnika na fakturze</div>
                <p>
                  Prosimy o zgłoszenie do opiekuna Migam jeżeli usługa będzie świadczona dla innego odbiorcy niż
                  wskazany poniżej.
                </p>
                <p><strong>Nazwa: </strong>{{offer.buyerOrganization.name}}</p>
                <p><strong>NIP: </strong>{{offer.buyerOrganization.nip}}</p>
                <p><strong>Ulica: </strong>{{offer.buyerOrganization.street}}</p>
                <p><strong>Kod: </strong>{{offer.buyerOrganization.zip}}</p>
                <p><strong>Miasto: </strong>{{offer.buyerOrganization.city}}</p>
              </div>
            </div>
            <div class="col" *ngIf="offer.isRecipientOtherThanBuyer">
              <div class="m-section">
                <div class="m-section-title">Dane odbiorcy na fakturze</div>
                <p><strong>Nazwa: </strong>{{offer.recipientOrganization.name}}</p>
                <p><strong>NIP: </strong>{{offer.recipientOrganization.nip}}</p>
                <p><strong>Ulica: </strong>{{offer.recipientOrganization.street}}</p>
                <p><strong>Kod: </strong>{{offer.recipientOrganization.zip}}</p>
                <p><strong>Miasto: </strong>{{offer.recipientOrganization.city}}</p>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="offer.isOfferForSubscriptionProduct">
            <div class="col">
              <div class="m-section">
                <div class="m-section-title">Godziny obsługi osób Głuchych</div>
                <p>
                  <strong>poniedziałek: </strong>{{offer.openingHours.monday.openTime}} -
                  {{offer.openingHours.monday.closeTime}} <br>
                </p>
                <p>
                  <strong>wtorek: </strong>{{offer.openingHours.tuesday.openTime}} -
                  {{offer.openingHours.tuesday.closeTime}} <br>
                </p>
                <p>
                  <strong>środa: </strong>{{offer.openingHours.wednesday.openTime}} -
                  {{offer.openingHours.wednesday.closeTime}} <br>
                </p>
                <p>
                  <strong>czwartek: </strong>{{offer.openingHours.thursday.openTime}} -
                  {{offer.openingHours.thursday.closeTime}} <br>
                </p>
                <p>
                  <strong>piątek: </strong>{{offer.openingHours.friday.openTime}} -
                  {{offer.openingHours.friday.closeTime}} <br>
                </p>
                <p>
                  <strong> sobota: </strong>{{offer.openingHours.saturday.openTime}} -
                  {{offer.openingHours.saturday.closeTime}} <br>
                </p>
                <p>
                  <strong> niedziela: </strong>{{offer.openingHours.sunday.openTime}} -
                  {{offer.openingHours.sunday.closeTime}} <br>
                </p>
              </div>
            </div>
          </div>

          <form [formGroup]="offerForm" novalidate>

            <div class="row">
              <div class="col">
                <div class="m-section">
                  <div class="m-section-title">Uzupełnij dane i kliknij "Zamawiam"</div>

                  <div class="row" *ngIf="offer.isOfferForSubscriptionProduct">
                    <div class="col">
                      <div>
                        W trakcie połączenia wideo z Głuchymi nasi tłumacze będą dzwonić do Twoich pracowników, aby
                        przetłumaczyć rozmowę.
                      </div>
                      <mat-form-field appearance="outline">
                        <mat-label>Numer lub numery telefonów do Twoich pracowników</mat-label>
                        <textarea matInput type="text" formControlName="phoneNumbersForInterpreters" required
                          cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="15"></textarea>
                        <mat-hint
                          *ngIf="offerForm.get('phoneNumbersForInterpreters').hasError('required') && offerForm.get('phoneNumbersForInterpreters').touched"
                          align="end">{{ 'offer_form.validations.field_required' | translate }}</mat-hint>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row" *ngIf="showLogoUploader">
                    <div class="col">
                      Po zamówieniu usługi otrzymasz unikalny link kierujący osoby Głuche do połączenia z tłumczem. Na
                      tej stronie będzie widoczne Twoje logo oraz godziny obsługi osób Głuchych w Twojej organizacji.
                      <app-logo-uploader [imageId]="offer.id" (uploadedImageUrl)="onUploadedLogo($event)"></app-logo-uploader>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col">
                      <div>
                        Adres email, na który będzie przesyłana faktura VAT
                      </div>
                      <mat-form-field appearance="outline">
                        <mat-label>Email do faktury</mat-label>
                        <input matInput type="email" formControlName="invoiceEmail" required>
                        <!--Required error-->
                        <mat-hint
                          *ngIf="offerForm.get('invoiceEmail').hasError('required') && offerForm.get('invoiceEmail').touched"
                          align="end">{{ 'offer_form.validations.field_required' | translate }}</mat-hint>
                        <!--Wrong email error-->
                        <mat-hint
                          *ngIf="!offerForm.get('invoiceEmail').hasError('required') && offerForm.get('invoiceEmail').hasError('email') && offerForm.get('invoiceEmail').touched"
                          align="end">{{ 'offer_form.validations.email_invalid' | translate }}</mat-hint>
                      </mat-form-field>
                    </div>
                  </div>

                </div>
              </div>
            </div>


            <!-- Error info -->
            <div class="m-error-info" *ngIf="submitFormErrorMsg">
              {{ submitFormErrorMsg }}
            </div>

            <!-- Submit button -->
            <div class="m-submit-button">
              <button type="button" mat-flat-button color="primary" class="login-button"
                [disabled]="!offerForm.valid || isSubmitProcessing || (showLogoUploader && !logoImageUrl)"
                (click)="onSubmit()">

                <span *ngIf="!isSubmitProcessing">{{ 'offer_form.submit_button' | translate }}</span>

                <mat-spinner diameter="25" *ngIf="isSubmitProcessing"></mat-spinner>
                <span *ngIf="isSubmitProcessing">{{ 'offer_form.submit_button_processing' | translate }}</span>

              </button>
            </div>

            <!-- Terms and privacy policy info -->
            <div class="m-consent-info">
              <p>
                <!-- {{ 'signup_form.terms.part1' | translate }} <a href="https://migam.org/pl/content/regulamin-uslugi-plus" target="_blank" rel="noopener noreferrer">{{ 'signup_form.terms.part2' | translate }}</a> {{'signup_form.terms.part3' | translate }}. -->
                Zamawiając Usługę oświadczasz, że zapoznałaś/eś się z <a
                  href="https://migam.org/regulamin-uslugi-abonamentowej-i-uslugi-jednorazowej" target="_blank"
                  rel="noopener noreferrer">Regulaminem</a> i akceptujesz jego treść oraz jesteś uprawniona/y do
                zawarcia Umowy w imieniu i na rzecz Klienta.
              </p>
              <p>
                <!-- {{ 'signup_form.privacy_policy.part1' | translate }} <a href="https://migam.org/pl/polityka-prywatnosci" target="_blank" rel="noopener noreferrer">{{ 'signup_form.privacy_policy.part2' | translate }}</a>. -->
                Administratorem danych osobowych zawartych w formularzu jest Migam S.A. (dawniej MIGAM „RKPK”
                spółka z ograniczoną odpowiedzialnością spółka komandytowo-akcyjna) z siedzibą w Warszawie. Dane
                będą przetwarzane w celu zawarcia i realizacji umowy o świadczenie usługi Tłumacza Migam, w tym
                bieżącego kontaktu. Więcej informacji o przetwarzaniu danych osobowych znajduje się w naszej <a
                  href="https://migam.org/pl/polityka-prywatnosci" target="_blank" rel="noopener noreferrer">Polityce
                  prywatności</a>.
              </p>
            </div>
          </form>

        </div>

      </div>
    </div>

  </div>
</div>