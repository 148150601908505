import { CloudFunctionResponse } from './../../../../shared/model/saas.interface';
import { TranslateService } from '@ngx-translate/core';
import { map, catchError, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClientConfirmationPayload } from '../../../../shared/model/saas.interface';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Component({
  selector: 'app-client-confirmation',
  templateUrl: './client-confirmation.component.html',
  styleUrls: ['./client-confirmation.component.scss']
})
export class ClientConfirmationComponent implements OnInit {

  isProcessing = true;
  confirmationResultMsg: string;

  constructor(
    private httpClient: HttpClient,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private firebaseFunctions: AngularFireFunctions,
  ) { }

  ngOnInit() {
    const signupRequestId = this.route.snapshot.paramMap.get('requestId');
    const subscriptionId = this.route.snapshot.queryParamMap.get('subscriptionId');

    this.confirmClient(signupRequestId, subscriptionId);
  }


  confirmClient(signupRequestId: string, subscriptionId: string) {

    const payload: ClientConfirmationPayload = {
      signupRequestId: signupRequestId,
      subscriptionId: subscriptionId
    }

    const clientConfirmationFunction = this.firebaseFunctions.httpsCallable('clientConfirmation');
    clientConfirmationFunction(payload).pipe(
      take(1),
      map((response: CloudFunctionResponse) => {

        if (response.success) {
          this.setConfirmMessage(this.translate.instant('client_confirmation.success_info'))

        } else {
          switch (response.error_code) {
            case 'request_not_found':
              this.setConfirmMessage(this.translate.instant('client_confirmation.errors.request_not_found'));
              break;
            case 'request_already_accepted':
              this.setConfirmMessage(this.translate.instant('client_confirmation.errors.request_already_accepted'));
              break;
            default:
              this.setConfirmMessage(this.translate.instant('client_confirmation.errors.unknown_error'));
              break;
          }
        }
      }),
      catchError(error => {
        this.setConfirmMessage(this.translate.instant('client_confirmation.errors.unknown_error'));
        throw error;
      })
    ).subscribe();

  }

  setConfirmMessage(message: string) {
    this.isProcessing = false;
    this.confirmationResultMsg = message;
  }

}
