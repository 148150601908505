import { SignupThankYouComponent } from './signup-thank-you/signup-thank-you.component';
import { OfferThankYouComponent } from './offer-thank-you/offer-thank-you.component';
import { OfferFormComponent } from './offer-form/offer-form.component';
import { SendOfferToClientComponent } from './send-offer-to-client/send-offer-to-client.component';
import { SubscriptionRenewalCancellationComponent } from './subscription-renewal-cancellation/subscription-renewal-cancellation.component';
import { ClientConfirmationComponent } from './client-confirmation/client-confirmation.component';
import { SignupFormComponent } from './signup-form/signup-form.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'signup',
    component: SignupFormComponent
  },
  {
    path: 'thank-you',
    component: SignupThankYouComponent
  },
  {
    path: 'confirm/:requestId',
    component: ClientConfirmationComponent
  },
  {
    path: 'cancel-subscription-renewal/:subscriptionId',
    component: SubscriptionRenewalCancellationComponent
  },
  {
    path: 'send-offer/:offerId',
    component: SendOfferToClientComponent
  },
  {
    path: 'offer/:offerId',
    component: OfferFormComponent
  },
  {
    path: 'offer-thank-you',
    component: OfferThankYouComponent
  },
  {
    path: '',
    redirectTo: '/signup',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
