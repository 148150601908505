import { CloudFunctionResponse } from './../../../../shared/model/saas.interface';
import { map, catchError, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from './../../environments/environment';
import { SendOfferToClientPayload } from '../../../../shared/model/saas.interface';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Component({
  selector: 'app-send-offer-to-client',
  templateUrl: './send-offer-to-client.component.html',
  styleUrls: ['./send-offer-to-client.component.scss']
})
export class SendOfferToClientComponent implements OnInit {

  isProcessing = true;
  sendResultMsg: string;

  constructor(
    private firebaseFunctions: AngularFireFunctions,
    private route: ActivatedRoute,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    const offerId = this.route.snapshot.paramMap.get('offerId');
    this.sendOfferToClient(offerId);
  }


  sendOfferToClient(offerId: string) {

    const payload: SendOfferToClientPayload = {
      offerId: offerId,
    }

    const sendOfferToClientFunction = this.firebaseFunctions.httpsCallable('sendOfferToClient');
    sendOfferToClientFunction(payload).pipe(
      take(1),
      map((response: CloudFunctionResponse) => {

        if (response.success) {
          this.setConfirmMessage(this.translate.instant('send_offer_to_client.success_info'))
        } else {
          switch (response.error_code) {
            case 'offer_not_found':
              this.setConfirmMessage(this.translate.instant('send_offer_to_client.errors.offer_not_found'));
              break;
            case 'offer_already_sent':
              this.setConfirmMessage(this.translate.instant('send_offer_to_client.errors.offer_already_sent'));
              break;
            case 'offer_already_accepted':
              this.setConfirmMessage(this.translate.instant('send_offer_to_client.errors.offer_already_accepted'));
              break;
            case 'offer_cancelled':
              this.setConfirmMessage(this.translate.instant('send_offer_to_client.errors.offer_cancelled'));
              break;
            default:
              this.setConfirmMessage(this.translate.instant('send_offer_to_client.errors.unknown_error'));
              break;
          }
        }
      }),
      catchError(error => {
        this.setConfirmMessage(this.translate.instant('send_offer_to_client.errors.unknown_error'));
        throw error;
      })
    ).subscribe();

    // // Send paylod to api endpoint
    // this.httpClient.post(
    //   environment.sendOfferToClientEndpointUrl,
    //   payload,
    // ).pipe(
    //   map(response =>
    //     this.setConfirmMessage(this.translate.instant('send_offer_to_client.success_info'))
    //   ),
    //   catchError(error => {
    //     console.error('Request failed', error)

    //     switch (error.error.error_code) {
    //       case 'offer_not_found':
    //         this.setConfirmMessage(this.translate.instant('send_offer_to_client.errors.offer_not_found'));
    //         break;
    //       case 'offer_already_sent':
    //         this.setConfirmMessage(this.translate.instant('send_offer_to_client.errors.offer_already_sent'));
    //         break;
    //       case 'offer_already_accepted':
    //         this.setConfirmMessage(this.translate.instant('send_offer_to_client.errors.offer_already_accepted'));
    //         break;
    //       case 'offer_cancelled':
    //         this.setConfirmMessage(this.translate.instant('send_offer_to_client.errors.offer_cancelled'));
    //         break;
    //       default:
    //         this.setConfirmMessage(this.translate.instant('send_offer_to_client.errors.unknown_error'));
    //         break;
    //     }

    //     throw error;
    //   })
    // ).subscribe();
  }

  setConfirmMessage(message: string) {
    this.isProcessing = false;
    this.sendResultMsg = message;
  }
}
